
@font-face {
	font-family: 'Montserrat UltraLight';
	src: url('/static/wp-thegem/fonts//montserrat-ultralight.eot');
	src: url('/static/wp-thegem/fonts/montserrat-ultralight.eot?#iefix') format('embedded-opentype'),
		url('/static/wp-thegem/fonts/montserrat-ultralight.woff') format('woff'),
		url('/static/wp-thegem/fonts/montserrat-ultralight.ttf') format('truetype'),
		url('/static/wp-thegem/fonts/montserrat-ultralight.svg#montserratultra_light') format('svg');
		font-weight: normal;
		font-style: normal;
}

/* GENERATED BY THEGEM THEME OPTIONS */

@media (min-width: 1212px) {
}


@media (min-width: 768px) and (max-width: 979px) {
	#site-header .header-main:not(.header-layout-fullwidth_hamburger) .site-title,
	#site-header .header-main:not(.header-layout-fullwidth_hamburger) .primary-navigation,
	#site-header .header-main:not(.header-layout-fullwidth_hamburger) #perspective-menu-buttons {
		display: block;
		text-align: center;
	}
	#site-header .header-main:not(.header-layout-fullwidth_hamburger):not(.logo-position-menu_center) .site-title {
		padding-top: 30px;
		padding-bottom: 0;
	}
	#site-header .header-main:not(.header-layout-fullwidth_hamburger).logo-position-right .site-title {
		padding-top: 0;
		padding-bottom: 30px;
	}
	#site-header.fixed.shrink .header-main:not(.header-layout-fullwidth_hamburger) .site-title {
		display: none;
	}
	#site-header .site-title a img.small {
		display: none !important;
	}
	#site-header .site-title a img.small,
	#site-header .menu-item-logo {
		display: none !important;
	}
}



@media (max-width: 767px) {
	#top-area {
		display: none;
	}
}

@media (max-width: 1212px) {
	#top-area {
		display: none;
	}
}
/*
#primary-menu.no-responsive > li > a,
#primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header a,
.widget_nav_menu > div > ul > li > a,
.widget_submenu > div > ul > li > a,
.widget_pages > ul > li > a,
.widget_categories > ul > li > a,
.widget_product_categories > ul > li > a {
	font-family: 'Montserrat';
}
*/

#primary-menu.no-responsive > li > a,
#primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header a,
.widget_nav_menu > div > ul > li > a,
.widget_submenu > div > ul > li > a,
.widget_pages > ul > li > a,
.widget_categories > ul > li > a,
.widget_product_categories > ul > li > a {
	font-weight: 700;
}

#primary-menu.no-responsive > li > a,
#primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header a,
.widget_nav_menu > div > ul > li > a,
.widget_submenu > div > ul > li > a,
.widget_pages > ul > li > a,
.widget_categories > ul > li > a,
.widget_product_categories > ul > li > a {
	font-size: 14px;
}

#primary-menu.no-responsive > li > a,
#primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header a,
.widget_nav_menu > div > ul > li > a,
.widget_submenu > div > ul > li > a,
.widget_pages > ul > li > a,
.widget_categories > ul > li > a,
.widget_product_categories > ul > li > a {
	line-height: 25px;
}

/*
#primary-menu.no-responsive > li.megamenu-enable > ul li > a,
#primary-menu.no-responsive > li li > a,
#primary-menu.no-responsive > li .minicart-product-title,
.portfolio-filters-resp ul li a,
.widget_nav_menu ul.menu,
.widget_submenu > div > ul,
.widget_categories > ul,
.widget_product_categories > ul,
.widget_pages > ul {
	font-family: 'Montserrat';
}
.primary-navigation.responsive li a {
	font-family: 'Montserrat';
}
*/

#primary-menu.no-responsive > li.megamenu-enable > ul li > a,
#primary-menu.no-responsive > li li > a,
#primary-menu.no-responsive > li .minicart-product-title,
.portfolio-filters-resp ul li a,
.widget_nav_menu ul.menu,
.widget_submenu > div > ul,
.widget_categories > ul,
.widget_product_categories > ul,
.widget_pages > ul {
	font-weight: normal;
}
.primary-navigation.responsive li a {
	font-weight: normal;
}


#primary-menu.no-responsive > li.megamenu-enable > ul li > a,
#primary-menu.no-responsive > li li > a,
#primary-menu.no-responsive > li .minicart-product-title,
.portfolio-filters-resp ul li a,
.widget_nav_menu ul.menu,
.widget_submenu > div > ul,
.widget_categories > ul,
.widget_product_categories > ul,
.widget_pages > ul {
	font-size: 16px;
}
.primary-navigation.responsive li a {
	font-size: 16px;
}

#primary-menu.no-responsive > li.megamenu-enable > ul li > a,
#primary-menu.no-responsive > li li > a,
#primary-menu.no-responsive > li .minicart-product-title,
.portfolio-filters-resp ul li a,
.widget_nav_menu ul.menu,
.widget_submenu > div > ul,
.widget_categories > ul,
.widget_product_categories > ul,
.widget_pages > ul {
	line-height: 20px;
}
.primary-navigation.responsive li a {
	line-height: 20px;
}
/*
.header-layout-overlay #primary-menu.no-responsive > li > a,
.header-layout-overlay #primary-menu.no-responsive > li li > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li li > a {
	font-family: 'Montserrat';
}
*/
.header-layout-overlay #primary-menu.no-responsive > li > a,
.header-layout-overlay #primary-menu.no-responsive > li li > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li li > a {
	font-weight: 700;
}

.header-layout-overlay #primary-menu.no-responsive > li > a,
.header-layout-overlay #primary-menu.no-responsive > li li > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li li > a,
.header-layout-overlay #primary-navigation #primary-menu.no-responsive li .menu-item-parent-toggle:before,
.page-additional-menu.header-layout-overlay #primary-navigation #primary-menu.no-responsive li .menu-item-parent-toggle:before {
	font-size: 32px;
}
@media (max-width: 767px) {
	.header-layout-overlay #primary-menu.no-responsive > li > a,
	.header-layout-overlay #primary-menu.no-responsive > li li > a,
	.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li > a,
	.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li li > a,
	.header-layout-overlay #primary-navigation #primary-menu.no-responsive li .menu-item-parent-toggle:before,
	.page-additional-menu.header-layout-overlay #primary-navigation #primary-menu.no-responsive li .menu-item-parent-toggle:before {
		font-size: 24px;
	}
}

.header-layout-overlay #primary-menu.no-responsive > li > a,
.header-layout-overlay #primary-menu.no-responsive > li li > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li li > a,
.header-layout-overlay #primary-navigation #primary-menu.no-responsive li .menu-item-parent-toggle:before,
.page-additional-menu.header-layout-overlay #primary-navigation #primary-menu.no-responsive li .menu-item-parent-toggle:before {
	line-height: 64px;
}
@media (max-width: 767px) {
	.header-layout-overlay #primary-menu.no-responsive > li > a,
	.header-layout-overlay #primary-menu.no-responsive > li li > a,
	.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li > a,
	.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li li > a,
	.header-layout-overlay #primary-navigation #primary-menu.no-responsive li .menu-item-parent-toggle:before,
	.page-additional-menu.header-layout-overlay #primary-navigation #primary-menu.no-responsive li .menu-item-parent-toggle:before {
		line-height: 48px;
	}
}

.styled-subtitle,
.diagram-circle .text div span.title,
.diagram-circle .text div span.summary,
.vc_pie_chart .vc_pie_chart_value,
form.cart .quantity .qty,
.shop_table .quantity .qty,
.woocommerce-before-cart .cart-short-info,
input[type="text"].coupon-code,
.cart_totals table th,
.order-totals table th,
.cart_totals table .shipping td,
.woocommerce-message,
.woocommerce-info,
.woocommerce ul.woocommerce-error li,
.woocommerce table.checkout-cart-info-table tr th,
.woocommerce table.checkout-cart-info-table tr.shipping td,
.widget_calendar caption,
.blog-style-timeline .post-time,
.gem-dropcap.gem-dropcap-style-medium,
.project-info-shortcode-style-default .project-info-shortcode-item .title,
.project_info-item-style-2 .project_info-item .title,
.diagram-legend .legend-element .title,
.single-product-content .price,
.widget_shopping_cart ul li .quantity,
.widget_shopping_cart .total span.amount {
	font-family: 'Source Sans Pro';
}

.styled-subtitle,
.diagram-circle .text div,
.diagram-circle .text div span.title,
.diagram-circle .text div span.summary,
.vc_pie_chart .vc_pie_chart_value,
form.cart .quantity .qty,
.shop_table .quantity .qty,
.woocommerce-before-cart .cart-short-info,
input[type="text"].coupon-code,
.cart_totals table th,
.order-totals table th,
.cart_totals table .shipping td,
.woocommerce-message,
.woocommerce-info,
.woocommerce ul.woocommerce-error li,
.woocommerce table.checkout-cart-info-table tr th,
.woocommerce table.checkout-cart-info-table tr.shipping td,
.widget_calendar caption,
.blog-style-timeline .post-time,
.gem-dropcap.gem-dropcap-style-medium,
.project-info-shortcode-style-default .project-info-shortcode-item .title,
.project_info-item-style-2 .project_info-item .title,
.diagram-legend .legend-element .title,
.single-product-content .price,
.widget_shopping_cart ul li .quantity,
.widget_shopping_cart .total span.amount {
	font-weight: 300;
}

.styled-subtitle,
.vc_pie_chart,
form.cart .quantity .qty,
.shop_table .quantity .qty,
.woocommerce-before-cart .cart-short-info,
input[type="text"].coupon-code,
.cart_totals table th,
.order-totals table th,
.cart_totals table .shipping td,
.woocommerce-message,
.woocommerce-info,
.woocommerce ul.woocommerce-error li,
.woocommerce table.checkout-cart-info-table tr th
.woocommerce table.checkout-cart-info-table tr.shipping td,
.widget_calendar caption,
.blog-style-timeline .post-time,
.gem-dropcap.gem-dropcap-style-medium,
.project-info-shortcode-style-default .project-info-shortcode-item .title,
.project_info-item-style-2 .project_info-item .title,
.diagram-legend .legend-element .title,
.widget_shopping_cart .total span.amount {
	font-size: 24px;
}
@media (max-width: 600px) {
	.styled-subtitle,
	.vc_pie_chart,
	form.cart .quantity .qty,
	.shop_table .quantity .qty,
	.woocommerce-before-cart .cart-short-info,
	input[type="text"].coupon-code,
	.cart_totals table th,
	.order-totals table th,
	.cart_totals table .shipping td,
	.woocommerce-message,
	.woocommerce-info,
	.woocommerce ul.woocommerce-error li,
	.woocommerce table.checkout-cart-info-table tr th
	.woocommerce table.checkout-cart-info-table tr.shipping td,
	.widget_calendar caption,
	.blog-style-timeline .post-time,
	.gem-dropcap.gem-dropcap-style-medium,
	.project-info-shortcode-style-default .project-info-shortcode-item .title,
	.project_info-item-style-2 .project_info-item .title,
	.diagram-legend .legend-element .title,
	.widget_shopping_cart .total span.amount{
		font-size: 4vw;
	}
}

.styled-subtitle,
.woocommerce-before-cart .cart-short-info,
.cart_totals table th,
.order-totals table th,
.cart_totals table .shipping td,
.woocommerce-message,
.woocommerce-info,
.woocommerce ul.woocommerce-error li,
.woocommerce table.checkout-cart-info-table tr th,
.woocommerce table.checkout-cart-info-table tr.shipping td,
.widget_calendar caption,
.blog-style-timeline .post-time,
.project-info-shortcode-style-default .project-info-shortcode-item .title,
.project_info-item-style-2 .project_info-item .title,
.diagram-legend .legend-element .title {
	line-height: 37px;
}
@media (max-width: 600px) {
	.styled-subtitle,
	.woocommerce-before-cart .cart-short-info,
	.cart_totals table th,
	.order-totals table th,
	.cart_totals table .shipping td,
	.woocommerce-message,
	.woocommerce-info,
	.woocommerce ul.woocommerce-error li,
	.woocommerce table.checkout-cart-info-table tr th,
	.woocommerce table.checkout-cart-info-table tr.shipping td,
	.widget_calendar caption,
	.blog-style-timeline .post-time,
	.project-info-shortcode-style-default .project-info-shortcode-item .title,
	.project_info-item-style-2 .project_info-item .title,
	.diagram-legend .legend-element .title {
		line-height: 6.1666666666667vw;
	}
}

/*
h1,
body .pricing-table-style-8 .pricing-cost,
.title-h1 {
	font-family: 'Anton';
}

h1,
body .pricing-table-style-8 .pricing-cost,
.title-h1 {
	font-weight: normal;
}
*/
h1,
.title-h1 {
	font-size: 35px!important;
	line-height: 40px!important;
}
@media (max-width: 1000px) {
	h1,
	.title-h1 {
		font-size: 5vw!important;
		line-height: 5vw!important;
	}
}
/*
h1,
.title-h1 {
	line-height: 69px!important;
}
@media (max-width: 1000px) {
	h1,
	.title-h1 {
		line-height: 6.9vw!important;
	}
}
*/

@media (max-width: 1199px) {
	h1,
	.title-h1 {
		font-size: 30px!important;
		line-height: 35px!important;
	}
}
@media (max-width: 767px) {
	h1,
	.title-h1 {
		font-size: 25px!important;
		line-height: 30px!important;
	}
}

/*
h2,
.title-h2,
h3.comment-reply-title,
body .pricing-table-style-6 .pricing-price-title {
	font-family: 'Anton';
}

h2,
.title-h2,
h3.comment-reply-title,
body .pricing-table-style-6 .pricing-price-title {
	font-weight: normal;
}
*/
h2,
.title-h2,
body .pricing-table-style-5  .pricing-price-title,
body .pricing-table-style-6 .pricing-price-title,
body .pricing-table-style-6 .pricing-price-subtitle,
h3.comment-reply-title,
body .pricing-table-style-2 .pricing-price-title {
	font-size: 36px;
}
@media (max-width: 1000px) {
	h2,
	.title-h2,
	body .pricing-table-style-5  .pricing-price-title,
	body .pricing-table-style-6 .pricing-price-title,
	body .pricing-table-style-6 .pricing-price-subtitle,
	h3.comment-reply-title,
	body .pricing-table-style-2 .pricing-price-title {
		font-size: 3.6vw;
	}
}

h2,
.title-h2,
body .pricing-table-style-6 .pricing-price-title,
h3.comment-reply-title {
	line-height: 53px;
}
@media (max-width: 1000px) {
	h2,
	.title-h2,
	body .pricing-table-style-6 .pricing-price-title,
	h3.comment-reply-title {
		line-height: 5.3vw;
	}
}

@media (max-width: 1199px) {
	h2,
	.title-h2,
	body .pricing-table-style-6 .pricing-price-title,
	h3.comment-reply-title {
		font-size: 34px;
	}
}
@media (max-width: 1199px) {
	h2,
	.title-h2,
	body .pricing-table-style-6 .pricing-price-title,
	h3.comment-reply-title {
		line-height: 42px;
	}
}
@media (max-width: 767px) {
	h2,
	.title-h2,
	body .pricing-table-style-6 .pricing-price-title,
	h3.comment-reply-title {
		font-size: 26px;
	}
}
@media (max-width: 767px) {
	h2,
	.title-h2,
	body .pricing-table-style-6 .pricing-price-title,
	h3.comment-reply-title {
		line-height: 32px;
	}
}
/*
h3,
.title-h3,
.cart_totals table .order-total td,
.woocommerce table.shop_table.order-details tr.cart_item td.product-total .amount,
.woocommerce table.shop_table.order-details tr.order_item td.product-total .amount,
.gem-dropcap,
.woocommerce .woocommerce-checkout-one-page #order_review table thead th {
	font-family: 'Anton';
}
*/

h3,
.title-h3,
.portfolio.columns-1 .portfolio-item .caption .title,
.cart_totals table .order-total td,
.woocommerce table.shop_table.order-details tr.cart_item td.product-total .amount,
.woocommerce table.shop_table.order-details tr.order_item td.product-total .amount,
.gem-dropcap,
.woocommerce .woocommerce-checkout-one-page #order_review table thead th {
	font-size: 28px;
}

h3,
.title-h3,
.portfolio.columns-1 .portfolio-item .caption .title,
.cart_totals table .order-total td,
.woocommerce table.shop_table.order-details tr.cart_item td.product-total .amount,
.woocommerce table.shop_table.order-details tr.order_item td.product-total .amount,
.woocommerce .woocommerce-checkout-one-page #order_review table thead th {
	line-height: 42px;
}

/*
h4,
.title-h4,
.widget .gem-teams-name,
body .pricing-table-style-3 .pricing_row_title,
body .pricing-table-style-8 .pricing_row_title,
body .pricing-table-style-4 .pricing_row_title,
.gem-gallery-hover-gradient .gem-gallery-item-title,
.gem-gallery-grid.hover-gradient .gallery-item .overlay .title,
.gem-gallery-hover-gradient .gem-gallery-preview-carousel-wrap .gem-gallery-item .gem-gallery-item-title {
	font-family: 'Anton';
}
@media only screen and (min-width: 992px) and (max-width: 1150px) {
	.with-sidebar .portfolio.hover-horizontal-sliding .overlay .links .caption .title {
		font-family: 'Anton';
	}
}
*/

h4,
.title-h4,
.widget .gem-teams-name,
body .pricing-table-style-3 .pricing_row_title,
body .pricing-table-style-4  .pricing_row_title,
body .pricing-table-style-8 .pricing_row_title,
.gem-gallery-hover-gradient .gem-gallery-item-title,
.gem-gallery-grid.hover-gradient .gallery-item .overlay .title,
.gem-gallery-hover-gradient .gem-gallery-preview-carousel-wrap .gem-gallery-item .gem-gallery-item-title {
	font-weight: normal;
}
@media only screen and (min-width: 992px) and (max-width: 1150px) {
	.with-sidebar .portfolio.hover-horizontal-sliding .overlay .links .caption .title {
		font-weight: normal;
	}
}

h4,
.title-h4,
body .pricing-table-style-7 .pricing-price-title,
body .pricing-table-style-4 .pricing_row_title,
body .pricing-table-style-3 .pricing_row_title,
body .pricing-table-style-2 .pricing-cost,
body .pricing-table-style-2 .time,
body .pricing-table-style-1 .pricing-price-title,
.widget .gem-teams-name,
body .pricing-table-style-8 .pricing_row_title,
.gem-gallery-hover-gradient .gem-gallery-item-title,
.gem-gallery-grid.hover-gradient .gallery-item .overlay .title,
.gem-gallery-hover-gradient .gem-gallery-preview-carousel-wrap .gem-gallery-item .gem-gallery-item-title,
body .vc_separator h4 {
	font-size: 24px;
}
@media only screen and (min-width: 992px) and (max-width: 1150px) {
	.with-sidebar .portfolio.hover-horizontal-sliding .overlay .links .caption .title {
		font-size: 24px;
	}
}

h4,
.title-h4,
body .pricing-table-style-7 .pricing-price-title,
body .pricing-table-style-5 .pricing-price-title,
body .pricing-table-style-4 .pricing_row_title,
body .pricing-table-style-3 .pricing_row_title,
body .pricing-table-style-2 .pricing-cost,
body .pricing-table-style-2 .time,
body .pricing-table-style-2 .pricing-price-title,
body .pricing-table-style-1 .pricing-price-title,
.widget .gem-teams-name,
.portfolio.columns-1 .portfolio-item .caption .title,
.gem-gallery-hover-gradient .gem-gallery-item-title,
.gem-gallery-grid.hover-gradient .gallery-item .overlay .title,
.gem-gallery-hover-gradient .gem-gallery-preview-carousel-wrap .gem-gallery-item .gem-gallery-item-title,
body .vc_separator h4 {
	line-height: 38px;
}
@media only screen and (min-width: 992px) and (max-width: 1150px) {
	.with-sidebar .portfolio.hover-horizontal-sliding .overlay .links .caption .title {
		line-height: 38px;
	}
}

/*
h5,
.gem-table thead th,
.title-h5,
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-search > .minisearch input[type="text"],
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-search > .minisearch input[type="text"],
.gem-teams-phone,
.shop_table td.product-price,
.shop_table td.product-subtotal,
.cart_totals table .cart-subtotal td,
.woocommerce-cart-form.responsive .cart-item .gem-table .shop_table td.product-subtotal,
.woocommerce table.shop_table.order-details thead tr th.product-name,
.woocommerce table.shop_table.order-details thead tr th.product-total,
.widget-gem-portfolio-item.gem-portfolio-dummy:after,
.resp-tabs-list li,
.gem-quote.gem-quote-style-4,
.gem-quote.gem-quote-style-5,
.blog-style-styled_list1 .post-time,
.gem-teams-phone,
blockquote.wp-block-quote.is-large,
blockquote.wp-block-quote.is-style-large,
.woocommerce .woocommerce-checkout.woocommerce-checkout-one-page .shop_table.woocommerce-checkout-payment-total th {
	font-family: 'Montserrat';
}
*/
h5,
.title-h5,
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-search > .minisearch input[type="text"],
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-search > .minisearch input[type="text"],
.gem-teams-phone,
.shop_table td.product-price,
.shop_table td.product-subtotal,
.cart_totals table .cart-subtotal td,
.woocommerce-cart-form.responsive .cart-item .gem-table .shop_table td.product-subtotal,
.woocommerce table.shop_table.order-details thead tr th.product-name,
.woocommerce table.shop_table.order-details thead tr th.product-total,
.widget-gem-portfolio-item.gem-portfolio-dummy:after,
.resp-tabs-list li,
.gem-quote.gem-quote-style-4,
.gem-quote.gem-quote-style-5,
.blog-style-styled_list1 .post-time,
.gem-teams-phone,
blockquote.wp-block-quote.is-large,
blockquote.wp-block-quote.is-style-large,
.woocommerce .woocommerce-checkout.woocommerce-checkout-one-page .shop_table.woocommerce-checkout-payment-total th {
	font-weight: 700;
}

h5,
.title-h5,
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-search > .minisearch input[type="text"],
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-search > .minisearch input[type="text"],
.gem-teams-phone,
.shop_table td.product-price,
.shop_table td.product-subtotal,
.cart_totals table .cart-subtotal td,
.woocommerce-cart-form.responsive .cart-item .gem-table .shop_table td.product-subtotal,
.woocommerce table.shop_table.order-details thead tr th.product-name,
.woocommerce table.shop_table.order-details thead tr th.product-total,
.widget-gem-portfolio-item.gem-portfolio-dummy:after,
.resp-tabs-list li,
.gem-quote.gem-quote-style-4,
.gem-quote.gem-quote-style-5,
.blog-style-styled_list1 .post-time,
.gem-teams-phone,
blockquote.wp-block-quote.is-large,
blockquote.wp-block-quote.is-style-large,
.woocommerce .woocommerce-checkout.woocommerce-checkout-one-page .shop_table.woocommerce-checkout-payment-total th {
	font-size: 19px;
}

h5,
.title-h5,
.gem-teams-phone,
.shop_table td.product-price,
.shop_table td.product-subtotal,
.cart_totals table .cart-subtotal td,
.woocommerce-cart-form.responsive .cart-item .gem-table .shop_table td.product-subtotal,
.woocommerce table.shop_table.order-details thead tr th.product-name,
.woocommerce table.shop_table.order-details thead tr th.product-total,
.widget-gem-portfolio-item.gem-portfolio-dummy:after,
.resp-tabs-list li,
.gem-quote.gem-quote-style-4,
.gem-quote.gem-quote-style-5,
.gem-teams-phone,
blockquote.wp-block-quote.is-large,
blockquote.wp-block-quote.is-style-large,
.woocommerce .woocommerce-checkout.woocommerce-checkout-one-page .shop_table.woocommerce-checkout-payment-total th {
	line-height: 30px;
}

/*
h6,
.title-h6,
.project_info-item-style-1 .project_info-item .title,
.project-info-shortcode-style-2 .project-info-shortcode-item .title,
.gem_accordion_header a,
#wp-calendar caption,
.hamburger-minicart .minicart-item-count,
.wpb_toggle,
.vc_toggle_title h4,
.testimonials-style-1-name.gem-testimonial-name,
.testimonials-style-2-name.gem-testimonial-name,
.diagram-wrapper .digram-line-box .skill-amount,
.diagram-wrapper.style-3 .digram-line-box .skill-title,
.row .vc_progress_bar .vc_label,
.pricing-column-top-choice .pricing-column-top-choice-text {
	font-family: 'Montserrat';
}
*/

h6,
.title-h6,
.project_info-item-style-1 .project_info-item .title,
.gem_accordion_header a,
#wp-calendar caption,
.hamburger-minicart .minicart-item-count,
.wpb_toggle,
.gem-table-responsive .tabletolist.rh > li > .titles,
.vc_toggle_title h4,
.testimonials-style-1-name.gem-testimonial-name,
.testimonials-style-2-name.gem-testimonial-name,
.diagram-wrapper .digram-line-box .skill-amount,
.diagram-wrapper.style-3 .digram-line-box  .skill-title,
.row .vc_progress_bar .vc_label {
	font-weight: 700;
}

h6,
.title-h6,
.project_info-item-style-1 .project_info-item .title,
.gem_accordion_header a,
#wp-calendar caption,
.wpb_toggle,
.gem-table-responsive .tabletolist.rh > li > .titles,
.vc_toggle_title h4,
.diagram-wrapper.style-3 .digram-line-box  .skill-title,
.row .vc_progress_bar .vc_label {
	font-size: 16px;
}

h6,
.title-h6,
.project_info-item-style-1 .project_info-item .title,
.gem_accordion_header a,
#wp-calendar caption,
.wpb_toggle,
.gem-table-responsive .tabletolist.rh > li > .titles,
.vc_toggle_title h4,
.diagram-wrapper.style-3 .digram-line-box  .skill-title,
.row .vc_progress_bar .vc_label {
	line-height: 25px;
}

/*
.title-xlarge {
	font-family: 'Anton';
}
*/

.title-xlarge {
	font-weight: normal;
}

.title-xlarge {
	font-size: 70px;
}
@media (max-width: 1000px) {
	.title-xlarge{
		font-size: 7vw;
	}
}

.title-xlarge {
	line-height: 60px;
}
@media (max-width: 1000px) {
	.title-xlarge {
		line-height: 6vw;
	}
}

@media (max-width: 1199px) {
	.title-xlarge {
		font-size: 70px;
	}
}
@media (max-width: 1199px) {
	.title-xlarge {
		line-height: 40px;
	}
}
@media (max-width: 767px) {
	.title-xlarge {
		font-size: 50px;
	}
}
@media (max-width: 767px) {
	.title-xlarge {
		line-height: 30px;
	}
}

h1 .light,
h2 .light,
h3 .light,
h4 .light,
h5 .light,
h6 .light,
.title-h1 .light,
.title-h2 .light,
.title-h3 .light,
.title-h4 .light,
.title-h5 .light,
.title-h6 .light,
.title-xlarge .light,
h1.light,
h2.light,
h3.light,
h4.light,
h5.light,
h6.light,
.title-h1.light,
.title-h2.light,
.title-h3.light,
.title-h4.light,
.title-h5.light,
.title-h6.light,
.title-xlarge.light,
.widget .gem-teams-name,
.gem-counter-style-2 .gem-counter-number,
.gem-gallery-grid .gallery-item .overlay .title,
.gem-quote.gem-quote-style-3 blockquote,
.row  .vc_pie_wrapper span,
.blog-style-styled_list1 .post-title,
.blog-style-styled_list2 .post-time,
.blog-style-styled_list2 .post-title,
.blog-style-timeline .post-title,
body .pricing-table-style-1 .pricing-price-title,
body .pricing-table-style-1  .pricing-cost,
body .pricing-table-style-2 .pricing-price-title,
body .pricing-table-style-2  .pricing-cost,
body .pricing-table-style-2 .time,
body .pricing-table-style-5  .pricing-price-title,
body .pricing-table-style-6 .pricing-price-subtitle,
body .pricing-table-style-7 .pricing-price-title,
body .pricing-table-style-7 .pricing-cost,
body .vc_grid-container ul.vc_grid-filter li.vc_grid-filter-item > span,
.gem-media-grid .vc_gitem-post-data-source-post_title a,
.gem-media-grid-2 .vc_gitem-post-data-source-post_title a,
.woocommerce .woocommerce-checkout-one-page #order_review table thead th,
.woocommerce .woocommerce-checkout.woocommerce-checkout-one-page .shop_table.woocommerce-checkout-payment-total th {
	font-family: 'Montserrat UltraLight';
}

h1 .light,
h2 .light,
h3 .light,
h4 .light,
h5 .light,
h6 .light,
.title-h1 .light,
.title-h2 .light,
.title-h3 .light,
.title-h4 .light,
.title-h5 .light,
.title-h6 .light,
.title-xlarge .light,
h1.light,
h2.light,
h3.light,
h4.light,
h5.light,
h6.light,
.title-h1.light,
.title-h2.light,
.title-h3.light,
.title-h4.light,
.title-h5.light,
.title-h6.light,
.title-xlarge.light,
.widget .gem-teams-name,
.gem-counter-style-2 .gem-counter-number,
.gem-quote.gem-quote-style-3 blockquote,
.row  .vc_pie_wrapper span,
.blog-style-styled_list1 .post-title,
.blog-style-styled_list2 .post-time,
.blog-style-timeline .post-title,
body .vc_grid-container ul.vc_grid-filter li.vc_grid-filter-item > span,
.gem-media-grid .vc_gitem-post-data-source-post_title a,
body .pricing-table-style-1 .pricing-price-title,
body .pricing-table-style-1 .pricing-cost,
body .pricing-table-style-2 .pricing-price-title,
body .pricing-table-style-2 .pricing-cost,
body .pricing-table-style-2 .time,
body .pricing-table-style-5 .pricing-price-title,
body .pricing-table-style-6 .pricing-price-subtitle,
body .pricing-table-style-7 .pricing-price-title,
body .pricing-table-style-7 .pricing-cost,
.gem-media-grid-2 .vc_gitem-post-data-source-post_title a,
.woocommerce .woocommerce-checkout-one-page #order_review table thead th,
.woocommerce .woocommerce-checkout.woocommerce-checkout-one-page .shop_table.woocommerce-checkout-payment-total th {
	font-weight: normal;
}
/*
body,
option,
.gem-table thead th,
.portfolio.columns-1 .portfolio-item .caption .subtitle,
.gallery-item .overlay .subtitle,
.cart_totals table .shipping td label,
.widget_archive li,
.woocommerce-cart-form.responsive .cart-item .gem-table .shop_table td.product-price,
.gem-media-grid-2 .vc_gitem-animated-block .vc_gitem-zone-b .vc_gitem-post-data-source-post_excerpt > div {
	font-family: 'Montserrat';
}
*/

body,
option,
.gem-table thead th,
.portfolio.columns-1 .portfolio-item .caption .subtitle,
.gallery-item .overlay .subtitle,
.cart_totals table .shipping td label,
.woocommerce-cart-form.responsive .cart-item .gem-table .shop_table td.product-price,
.gem-media-grid-2 .vc_gitem-animated-block .vc_gitem-zone-b .vc_gitem-post-data-source-post_excerpt > div {
	font-weight: normal;
}

body,
.gem-table thead th,
.inline-column,
.inline-inside > *,
option,
.portfolio.columns-1 .portfolio-item .caption .subtitle,
.gallery-item .overlay .subtitle,
.cart_totals table .shipping td label,
.woocommerce-cart-form.responsive .cart-item .gem-table .shop_table td.product-price,
.portfolio-filters a,
.gem-media-grid-2 .vc_gitem-animated-block .vc_gitem-zone-b .vc_gitem-post-data-source-post_excerpt > div {
	font-size: 16px;
}

body,
option,
.inline-column,
.inline-inside > *,
.related-element-info > a,
.gallery-item .overlay .subtitle,
.cart_totals table .shipping td label,
.woocommerce-cart-form.responsive .cart-item .gem-table .shop_table td.product-price,
.gem-media-grid-2 .vc_gitem-animated-block .vc_gitem-zone-b .vc_gitem-post-data-source-post_excerpt > div {
	line-height: 25px;
}

.page-title-excerpt,
.custom-title-excerpt {
	font-family: 'Montserrat UltraLight';
}

.page-title-excerpt,
.custom-title-excerpt {
	font-weight: normal;
}

.page-title-excerpt,
.custom-title-excerpt {
	font-size: 19px;
}
@media (max-width: 600px) {
	.page-title-excerpt,
	.custom-title-excerpt {
		font-size: 3.1666666666667vw;
	}
}

.page-title-excerpt,
.custom-title-excerpt {
	line-height: 30px;
}
@media (max-width: 600px) {
	.page-title-excerpt,
	.custom-title-excerpt {
		line-height: 5vw;
	}
}

@media (max-width: 1199px) {
	.page-title-excerpt,
	.custom-title-excerpt {
		font-size: 19px;
	}
}
@media (max-width: 1199px) {
	.page-title-excerpt,
	.custom-title-excerpt {
		line-height: 30px;
	}
}
@media (max-width: 767px) {
	.page-title-excerpt,
	.custom-title-excerpt {
		font-size: 19px;
	}
}
@media (max-width: 767px) {
	.page-title-excerpt,
	.custom-title-excerpt {
		line-height: 30px;
	}
}
/*
.widget-title {
	font-family: 'Montserrat';
}
*/

.widget-title {
	font-weight: 700;
}

.widget-title {
	font-size: 19px;
}

.widget-title {
	line-height: 30px;
}
/*
.gem-button,
input[type='submit'],
.gem-pagination a,
.gem-pagination .current,
.blog-load-more button,
body .wp-block-button .wp-block-button__link,
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-page a {
	font-family: 'Montserrat';
}
*/
.gem-button,
input[type='submit'],
.gem-pagination a,
.gem-pagination .current,
.blog-load-more button,
body .wp-block-button .wp-block-button__link,
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-page a {
	font-weight: 700;
}

.gem-button.gem-button-text-weight-thin {
	font-family: 'Montserrat UltraLight';
}

.gem-button.gem-button-text-weight-thin {
	font-weight: normal;
}
/*
.gem-nivoslider-title {
	font-family: 'Montserrat';
}
*/

.gem-nivoslider-title {
	font-weight: 700;
}

.gem-nivoslider-title {
	font-size: 50px;
}

.gem-nivoslider-title {
	line-height: 69px;
}

.gem-nivoslider-description {
	font-family: 'Source Sans Pro';
}

.gem-nivoslider-description {
	font-weight: normal;
}

.gem-nivoslider-description {
	font-size: 16px;
}

.gem-nivoslider-description {
	line-height: 25px;
}
/*
.portfolio-item-title,
.portfolio-item .wrap > .caption .title,
.fancybox-title .slide-info-title,
.blog.blog-style-masonry article .description .title {
	font-family: 'Montserrat';
}
*/
.portfolio-item-title,
.portfolio-item .wrap > .caption .title,
.fancybox-title .slide-info-title,
.blog.blog-style-masonry article .description .title {
	font-weight: 700;
}

.portfolio-item-title,
.portfolio-item .wrap > .caption .title,
.fancybox-title .slide-info-title,
.blog.blog-style-masonry article .description .title {
	font-size: 16px;
}

.portfolio-item-title,
.portfolio-item .wrap > .caption .title,
.fancybox-title .slide-info-title,
.blog.blog-style-masonry article .description .title {
	line-height: 24px;
}

.portfolio-item-description,
.portfolio-item .caption .subtitle,
.fancybox-title .slide-info-summary,
.blog.blog-style-masonry article .description .summary {
	font-family: 'Source Sans Pro';
}

.portfolio-item-description,
.portfolio-item .caption .subtitle,
.fancybox-title .slide-info-summary,
.blog.blog-style-masonry article .description .summary {
	font-weight: normal;
}

.portfolio-item-description,
.portfolio-item .caption .subtitle,
.fancybox-title .slide-info-summary,
.blog.blog-style-masonry article .description .summary {
	font-size: 16px;
}

.portfolio-item-description,
.portfolio-item .caption .subtitle,
.fancybox-title .slide-info-summary,
.blog.blog-style-masonry article .description .summary {
	line-height: 24px;
}
.portfolio:not(.columns-1):not(.portfolio-style-masonry) .portfolio-item .wrap > .caption .subtitle {
	max-height: 24px;
}
/*
.quickfinder-item-title {
	font-family: 'Montserrat';
}
*/

.quickfinder-item-title {
	font-weight: 700;
}

.quickfinder-item-title {
	font-size: 19px;
}

.quickfinder-item-title {
	line-height: 28px;
}
/*
.quickfinder.quickfinder-title-thin .quickfinder-item-title {
	font-family: 'Montserrat';
}
*/

.quickfinder.quickfinder-title-thin .quickfinder-item-title {
	font-weight: 300;
}

.quickfinder.quickfinder-title-thin .quickfinder-item-title {
	font-size: 19px;
}

.quickfinder.quickfinder-title-thin .quickfinder-item-title {
	line-height: 38px;
}

.quickfinder-item-text {
	font-family: 'Source Sans Pro';
}

.quickfinder-item-text {
	font-weight: normal;
}

.quickfinder-item-text {
	font-size: 16px;
}

.quickfinder-item-text {
	line-height: 25px;
}

.gem-gallery-hover-zooming-blur .gem-gallery-item-title,
.gem-gallery-grid .gallery-item .overlay .title,
.gem-gallery .gem-gallery-caption .gem-gallery-item-title{
	font-family: 'Montserrat UltraLight';
}

.gem-gallery-hover-zooming-blur .gem-gallery-item-title,
.gem-gallery-grid .gallery-item .overlay .title,
.gem-gallery .gem-gallery-caption  .gem-gallery-item-title{
	font-weight: normal;
}

.gem-gallery-hover-zooming-blur .gem-gallery-item-title,
.gem-gallery-grid .gallery-item .overlay .title,
.gem-gallery .gem-gallery-caption  .gem-gallery-item-title{
	font-size: 24px;
}

.gem-gallery-hover-zooming-blur .gem-gallery-item-title,
.gem-gallery-grid .gallery-item .overlay .title,
.gem-gallery .gem-gallery-caption  .gem-gallery-item-title
	{
	line-height: 30px;
}

/*
.gem-gallery-grid.hover-default .gallery-item .overlay .title,
.gem-gallery-hover-default .gem-gallery-item .gem-gallery-item-title{
font-family: 'Montserrat';
}
*/

.gem-gallery-grid.hover-default .gallery-item .overlay .title,
.gem-gallery-hover-default .gem-gallery-item .gem-gallery-item-title {
font-weight: 700;
}

.gem-gallery-grid.hover-default .gallery-item .overlay .title,
.gem-gallery-hover-default .gem-gallery-item .gem-gallery-item-title {
font-size: 24px;
}

.gem-gallery-grid.hover-default .gallery-item .overlay .title,
.gem-gallery-hover-default .gem-gallery-item .gem-gallery-item-title {
line-height: 31px;
}




.gallery-description,
.gem-gallery-item-description {
	font-family: 'Source Sans Pro';
}

.gallery-description,
.gem-gallery-item-description {
	font-weight: 300;
}

.gallery-description,
.gem-gallery-item-description {
	font-size: 17px;
}

.gallery-description,
.gem-gallery-item-description {
	line-height: 24px;
}


body .gem-testimonial-text,
body .testimonials-style-1-text {
	font-family: 'Source Sans Pro';
}

body .gem-testimonial-text,
body .testimonials-style-1-text {
	font-weight: 300;
}

body .gem-testimonial-text,
body .testimonials-style-1-text {
	font-size: 24px;
}
@media (max-width: 600px) {
	body .gem-testimonial-text,
	body .testimonials-style-1-text {
		font-size: 4vw;
	}
}

body .gem-testimonial-text,
body .testimonials-style-1-text {
	line-height: 36px;
}
@media (max-width: 600px) {
	body .gem-testimonial-text,
	body .testimonials-style-1-text {
		line-height: 6vw;
	}
}
/*
.gem-counter-number,
.diagram-circle .text div span {
	font-family: 'Montserrat';
}
*/

.gem-counter-number,
.diagram-circle .text div span {
	font-weight: 700;
}

.gem-counter-number {
	font-size: 50px;
}

.gem-counter-number {
	line-height: 69px;
}

.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-tab.vc_active > a,
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-style-outline .vc_tta-tab a,
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-style-modern .vc_tta-tab a,
.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-panel.vc_active .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-panel.vc_active .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-style-outline .vc_tta-panel .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-outline .vc_tta-panel .vc_tta-panel-title
.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-panel.vc_tta-style-modern .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-modern .vc_tta-panel .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-simple_dashed .vc_tta-panel .vc_tta-panel-title {
	font-family: 'Montserrat UltraLight';
}


.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-tab.vc_active > a,
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-style-outline .vc_tta-tab a,
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-style-modern .vc_tta-tab a,
.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-panel.vc_active .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-panel.vc_active .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-style-outline .vc_tta-panel .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-outline .vc_tta-panel .vc_tta-panel-title
.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-panel.vc_tta-style-modern .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-modern .vc_tta-panel .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-simple_dashed .vc_tta-panel .vc_tta-panel-title {
	font-size: 16px;
}


.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-tab a,
.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-panel .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-panel .vc_tta-panel-title {
	font-family: 'Montserrat UltraLight';
}


.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-tab a,
.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-panel .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-panel .vc_tta-panel-title {
	font-size: 16px;
}

.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-tab a,
.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-panel .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-panel .vc_tta-panel-title {
	line-height: 18px;
}
/*
.widget_shopping_cart_content .quantity,
.widget_shopping_cart_content .total .amount {
	font-family: 'Montserrat';
}

.product-info .product-title.title-h6,
.products .portfolio-item .wrap > .caption .title {
	font-family: 'Montserrat';
}
*/

.product-info .product-title.title-h6,
.products .portfolio-item .wrap > .caption .title {
	font-weight: 700;
}

.product-info .product-title.title-h6,
.products .portfolio-item .wrap > .caption .title {
	font-size: 16px;
}

.product-info .product-title.title-h6,
.products .portfolio-item .wrap > .caption .title {
	line-height: 25px;
}

.single-product-content .product_title {
	font-family: 'Montserrat UltraLight';
}

.single-product-content .product_title {
	font-weight: normal;
}

.single-product-content .product_title {
	font-size: 28px;
}

.single-product-content .product_title {
	line-height: 42px;
}

.widget .gem-products-title {
	font-family: 'Source Sans Pro';
}

.widget .gem-products-title {
	font-weight: normal;
}

.widget .gem-products-title {
	font-size: 16px;
}

.widget .gem-products-title {
	line-height: 25px;
}

.shop_table .product-name .product-title {
	font-family: 'Source Sans Pro';
}

.shop_table .product-name .product-title {
	font-weight: normal;
}

.shop_table .product-name .product-title {
	font-size: 16px;
}

.shop_table .product-name .product-title {
	line-height: 25px;
}

.products .product-info .price ins,
.products .product-info .price .amount {
	font-family: 'Source Sans Pro';
}

.products .product-info .price ins,
.products .product-info .price .amount {
	font-weight: normal;
}

.products .product-info .price ins,
.products .product-info .price .amount {
	font-size: 16px;
}

.products .product-info .price ins,
.products .product-info .price .amount {
	line-height: 25px;
}

.single-product-content .price {
	font-family: 'Source Sans Pro';
}

.single-product-content .price {
	font-weight: 300;
}

.woocommerce .single-product-content .price {
	font-size: 36px;
}
.woocommerce .single-product-content .price del,
.woocommerce .single-product-content .single_variation .price del {
	font-size: 24px;
}
.woocommerce .single-product-content .single_variation .price {
	font-size: 50px;
}
.woocommerce .single-product-content .single_variation .price del {
	line-height: 24px;
}
.woocommerce .single-product-content .single_variation .price {
	line-height: 50px;
}

.woocommerce .single-product-content .price {
	line-height: 36px;
}
.woocommerce .single-product-content .price del {
	line-height: 24px;
}

.widget .gem-products-price ins,
.widget .gem-products-price .amount {
	font-family: 'Source Sans Pro';
}

.widget .gem-products-price ins,
.widget .gem-products-price .amount {
	font-weight: 300;
}

.widget .gem-products-price ins,
.widget .gem-products-price .amount {
	font-size: 20px;
}

.widget .gem-products-price ins,
.widget .gem-products-price .amount {
	line-height: 30px;
}

.woocommerce .woocommerce-cart-form .shop_table td.product-price,
.woocommerce .woocommerce-cart-form .shop_table td.product-subtotal,
.woocommerce .wishlist_table .product-info .product-info-content .product-price {
	font-family: 'Source Sans Pro';
}

.woocommerce .woocommerce-cart-form .shop_table td.product-price,
.woocommerce .woocommerce-cart-form .shop_table td.product-subtotal,
.woocommerce .wishlist_table .product-info .product-info-content .product-price {
	font-weight: 300;
}

.woocommerce .woocommerce-cart-form .shop_table td.product-subtotal,
.woocommerce .wishlist_table .product-info .product-info-content .product-price {
	font-size: 24px;
}
.woocommerce .woocommerce-cart-form .shop_table td.product-price {
	font-size: 19.9992px;
}

.woocommerce .woocommerce-cart-form .shop_table td.product-price,
.woocommerce .wishlist_table .product-info .product-info-content .product-price {
	line-height: 30px;
}
.woocommerce .woocommerce-cart-form .shop_table td.product-subtotal {
	line-height: 24.999px;
}

body {
	background-color: #f0f3f2;
}

#site-header,
#site-header-wrapper,
.header-layout-fullwidth_hamburger #primary-menu.no-responsive,
#thegem-perspective .perspective-navigation:not(.responsive) {
	background-color: #f3f3f3;
}
#site-header.fixed.shrink {
	background-color: rgba(243, 243, 243, 0.95);
}
#site-header.fixed.shrink.fill {
	background-color: rgba(243, 243, 243, 1);
}

.block-content,
.combobox-wrapper,
#page .woocommerce .select2-container .select2-selection--single,
.select2-container .select2-dropdown,
.gem-team-style-5 .team-person:hover .team-person-hover,
.gem-table td,
body .gem-table-style-2 thead,
body .gem-table-style-2 tr:nth-child(2n) td,
.gem-table .tabletolist.nrh > li > ul > li,
.resp-tabs-container,
.resp-tab-content,
.resp-tab-active,
.gem-clients-type-carousel-grid .gem-client-item a:hover,
.gem_client-carousel .gem-client-item a:hover,
.products .product-inner,
.gem-table .shop_table tbody tr,
.widget_product_search input.search-field,
.woocommerce-message:before,
.woocommerce-info:before,
.woocommerce table.shop_table.order-details tr.cart_item,
.woocommerce table.shop_table.order-details tr.order_item,
.triangle-with-shadow:after,
.blog-style-default article .post-image .gem-dummy,
.blog-style-default article.sticky .post-image .gem-dummy:after,
.blog-style-timeline.blog-style-styled_list1 .item-post .post-text,
.blog-style-default article.no-image .post-image .gem-dummy,
.wpb_toggle,
.vc_toggle_title,
.page-title-style-2,
.post-tags-list a:after,
.portfolio .portfolio-item .overlay-line,
.portfolio-item .wrap > .caption,
.portfolio.background-style-dark .portfolio-item:hover .wrap > .caption,
.portfolio.background-style-dark .caption-bottom-line .project-button .gem-button,
.quickfinder-item .quickfinder-animation,
.gem-counter .gem-counter-animation,
.mc4wp-form input[type='email'],
.page-title-block.page-title-style-2,
.page-title-block.has-background-image,
body .widget .tagcloud a,
.wpcf7-form.gem-contact-form-white .combobox-wrapper,
.wpcf7-form.gem-contact-form-white  input:not(.gem-button-wpcf-custom),
.wpcf7-form.gem-contact-form-white .combobox-wrapper .combobox-button,
.wpcf7-form.gem-contact-form-white .combobox-wrapper .combobox-text,
.wpcf7-form.gem-contact-form-white .checkbox-sign,
.wpcf7-form.gem-contact-form-white  span.radio-sign,
.wpcf7-form.gem-contact-form-white textarea,
.diagram-wrapper.style-1 .digram-line-box .skill-line,
.blog-style-styled_list1 .post-item,
.blog-style-styled_list2 article,
.blog-style-styled_list2 .post-item,
.blog-style-styled_list2 .sticky,
.blog-style-timeline .post-item,
.blog-style-timeline .post-img,
.blog-style-timeline .post-img a .dummy,
.gem-basic-grid .vc_grid-item-mini,
.gem-basic-grid .vc_gitem-zone-c,
.shop-widget-area .widget .gem-products-content,
.blog-style-compact article,
.gem-search-form.gem-search-form-style-light .search-field,
.page-title-block.custom-page-title {
	background-color: #ffffff;
}
.portfolio.hover-gradient .portfolio-item .image .overlay .links a.icon:before,
.gem-gallery-grid.hover-gradient .gallery-item .overlay a.icon::before,
.gem-gallery-hover-gradient .gem-gallery-preview-carousel-wrap .gem-gallery-item a:after {
	background-color: rgba(255, 255, 255, 0.8);
}
.portfolio-item .wrap > .caption .caption-sizable-content:after,
.portfolio.background-style-dark .portfolio-item:hover .wrap > .caption .caption-sizable-content:after {
	box-shadow: 0 0 30px 45px #ffffff;
}
.blog-style-compact article .gem-compact-item-content:after {
	box-shadow: 0 5px 15px 15px #ffffff;
}
.fullwidth-block .marker-direction-inside svg,
.blog-style-styled_list1 .wrap-style,
.blog-style-styled_list2 .wrap-style,
.blog-style-timeline .wrap-style {
	fill: #ffffff;
}
.gem-image a:after,
.gem-textbox-picture a:after,
.blog-style-default article.sticky .sticky-label,
.blog-style-masonry article.sticky .sticky-label,
.blog-style-styled_list1 article.sticky .sticky-label,
.blog-style-styled_list2 article.sticky .sticky-label,
.blog-style-timeline article.sticky .sticky-label,
.justified-style-1 article.sticky .sticky-label,
.justified-style-2 article.sticky .sticky-label,
.blog-style-multi-author article.sticky .sticky-label,
.blog article .post-featured-content > a:after,
.blog .gem-simple-gallery .gem-gallery-item a:after,
.blog-style-styled_list1 .wrap-style,
.blog-style-styled_list2 .wrap-style,
.blog-style-styled_list1 article a.default:after,
.blog-style-styled_list2 article a.default:after,
.blog-style-compact article a.default:after,
.blog-style-timeline .wrap-style,
#primary-navigation #primary-menu > li.menu-item-cart > a .minicart-item-count,
.mobile-cart-position-top #site-header .mobile-cart .minicart-item-count,
.hamburger-minicart > a .minicart-item-count,
.product .onsale,
.product .new-label,
.product .out-of-stock-label,
.woocommerce ul.woocommerce-error li:before,
.pricing-column-top-choice .pricing-column-top-choice-text,
.widget .onsale:after,
.widget_shopping_cart ul li.sale .minicart-image:before,
#wp-calendar caption,
.widget.widget_calendar a:hover,
.shop-widget-area .onsale,
.socials-sharing li a:hover:after,
.wpb_wrapper .vc_progress_bar .vc_label_units,
.portfolio-item .image .overlay .links a.icon:before,
.portfolio.hover-title .portfolio-item .caption .title,
.portfolio.hover-title.columns-4 .portfolio-item .caption .title,
.portfolio.hover-title .portfolio-item .caption .subtitle,
.portfolio.hover-title .portfolio-item .caption .info,
.portfolio.hover-title .portfolio-item .caption .info a,
.portfolio.hover-default .portfolio-item .image .overlay .links .caption .info a,
.portfolio .portfolio-item .image .overlay .links .portfolio-sharing-pane .socials-item,
.portfolio.products .portfolio-item .image .overlay .portfolio-icons.product-bottom a.added_to_cart,
.portfolio.products .portfolio-item .image .overlay .portfolio-icons.product-bottom .yith-wcwl-wishlistaddedbrowse a,
.news-grid.portfolio.hover-title .portfolio-item .caption .title > *,
.news-grid.portfolio.hover-default .comments-link a,
.news-grid.portfolio.hover-vertical-sliding .comments-link a,
.news-grid.portfolio.hover-gradient .comments-link a,
.news-grid.portfolio.hover-circular .comments-link a,
.news-grid.portfolio.hover-default .zilla-likes,
.news-grid.portfolio.hover-vertical-sliding .zilla-likes,
.news-grid.portfolio.hover-gradient .zilla-likes,
.news-grid.portfolio.hover-circular .zilla-likes,
.news-grid.hover-default .portfolio-item .image .links .caption .author .author-name,
.news-grid.hover-default .portfolio-item .image .links .caption .author .author-name a,
.news-grid.hover-vertical-sliding .portfolio-item .image .links .caption .author .author-name,
.news-grid.hover-vertical-sliding .portfolio-item .image .links .caption .author .author-name a,
.news-grid.hover-default .portfolio-item .image .links .caption .description .subtitle,
.news-grid.hover-vertical-sliding .portfolio-item .image .links .caption .description .subtitle,
.news-grid.hover-horizontal-sliding .portfolio-item .image .links .caption .description .subtitle,
.news-grid.hover-vertical-sliding .portfolio-item .image .links .caption .post-date,
.news-grid.hover-horizontal-sliding .portfolio-item .image .links .caption .post-date,
.news-grid.hover-gradient .portfolio-item .image .links .caption .post-date,
.news-grid.hover-circular .portfolio-item .image .links .caption .post-date,
.news-grid.portfolio.hover-vertical-sliding .portfolio-item .image .overlay .links .caption .info a,
.news-grid.portfolio.hover-vertical-sliding .portfolio-item .image .overlay .links .caption .info span.sep,
.news-grid.hover-gradient .portfolio-item .image .links .caption .author .author-name,
.news-grid.hover-gradient .portfolio-item .image .links .caption .author .author-name a,
.news-grid.hover-circular .portfolio-item .image .links .caption .author .author-name,
.news-grid.hover-circular .portfolio-item .image .links .caption .author .author-name a,
.news-grid.hover-gradient.title-on-page.title-style-dark .portfolio-item .image .overlay .links .caption .subtitle,
.news-grid.hover-circular.title-on-page.title-style-dark .portfolio-item .image .overlay .links .caption .subtitle,
.news-grid.hover-circular .portfolio-item .image .links .caption .post-date,
.news-grid .portfolio-item.double-item-style-alternative .highlight-item-alternate-box .post-date,
.news-grid .portfolio-item.double-item-style-alternative .highlight-item-alternate-box .title > *,
.news-grid .portfolio-item.double-item-style-alternative .highlight-item-alternate-box .info a,
.portfolio-filters a:hover,
.portfolio-filters a.active,
.portfolio.background-style-gray .portfolio-item:hover .wrap > .caption .title,
.portfolio.background-style-dark .portfolio-item:not(:hover) .wrap > .caption .title,
.gallery-item .overlay .title,
.gallery-item .overlay .subtitle,
.gallery-item .overlay a.icon:before,
.gem-gallery .gem-gallery-preview-carousel-wrap:hover .gem-prev:after,
.gem-gallery .gem-gallery-preview-carousel-wrap:hover .gem-next:after,
.gem-gallery .gem-gallery-preview-carousel-wrap .gem-gallery-item a:after,
.gem-gallery .gem-gallery-preview-carousel-wrap .gem-gallery-item .gem-gallery-caption,
.gem-gallery-hover-zooming-blur .gem-gallery-preview-carousel-wrap .gem-gallery-item a:after,
.gem-gallery-hover-gradient .gem-gallery-preview-carousel-wrap .gem-gallery-item a:after,
.gem-simple-gallery .gem-gallery-item a:after,
.post-tags-list a:hover,
div.blog article a.youtube:after,
div.blog article a.vimeo:after,
div.blog article a.self_video:after,
#colophon  .recentcomments .comment-author-link a:hover,
#colophon .project_info-item-style-1 .project_info-item-title,
#colophon .gem-contacts-item a:hover,
#colophon .widget_calendar td a,
#colophon .widget.widget_search .gem-button:hover,
#colophon .wpcf7 .wpcf7-form .contact-form-footer .wpcf7-submit:hover,
#colophon #mc4wp_submit:hover,
body .widget .tagcloud a:hover,
.gem-table-responsive.gem-table-style-3 .tabletolist > li .titles,
.gem-table-style-3 thead tr th,
.gem-table-style-3 thead h6,
.breadcrumbs a,
.bc-devider:before,
body .vc_grid-container ul.vc_grid-filter li.vc_grid-filter-item.vc_active > span,
body .vc_grid-container ul.vc_grid-filter li.vc_grid-filter-item:hover > span,
.vc_grid-item.gem-media-grid .vc_gitem-post-data-source-post_title a,
.gem-media-grid-2 .midia-grid-item-post-author,
.gem-media-grid-2 .midia-grid-item-post-author span a,
.widget .gem-dummy:after,
.portfolio.hover-gradient .portfolio-item .image .overlay .links .caption .info a,
.portfolio.hover-gradient .portfolio-item .image .overlay .links .caption .info span.sep,
.portfolio.hover-gradient .portfolio-item .image .overlay .links .caption .subtitle,
.portfolio.hover-gradient .portfolio-item .image .overlay .links .caption .info,
.portfolio.hover-gradient .portfolio-item .image .overlay .links .caption .title,
.portfolio.hover-gradient .portfolio-item .image .overlay .links .portfolio-sharing-pane .socials-item,
.diagram-wrapper.style-3 .digram-line-box  .skill-title,
.gem-blog-slider .post-title a,
#primary-menu.no-responsive > li.megamenu-enable > ul > li .mega-label,
.gem-search-form.gem-search-form-style-dark .search-submit,
.products .quick-view-button {
	color: #ffffff;
}
.news-grid.portfolio.hover-default .portfolio-item .grid-post-meta .comments-link,
.news-grid.portfolio.hover-vertical-sliding .portfolio-item .grid-post-meta .comments-link,
.news-grid.portfolio.hover-gradient .portfolio-item .grid-post-meta .comments-link,
.news-grid.portfolio.hover-circular .portfolio-item .grid-post-meta .comments-link {
	border-right-color: #ffffff;
}
.portfolio.hover-default .portfolio-item .image .overlay .links .caption .info span.sep,
.portfolio.hover-vertical-sliding .portfolio-item .image .overlay .links .caption .info span.sep,
.portfolio.hover-gradient .portfolio-item .image .overlay .links .caption .info span.sep,
.portfolio.hover-circular .portfolio-item .image .overlay .links .caption .info span.sep,
.news-grid .portfolio-item.double-item-style-alternative .highlight-item-alternate-box .info span.sep,
.news-grid.portfolio.hover-circular .portfolio-item .image .overlay .links .caption .info span.sep {
	border-left-color: #ffffff;
}
@media (min-width: 768px) {
	.blog-style-timeline .item-post .post-text:after {
		border-right: 20px solid #ffffff;
	}
}
@media (max-width: 768px) {
	.blog-style-timeline .item-post .post-text:after {
		border-bottom: 30px solid #ffffff;
	}
}
@media (max-width: 768px) {
	.blog-style-timeline .post-text:after,
	.blog-style-timeline.blog-style-styled_list1 .post-text:after,
	.blog-style-timeline.blog-style-styled_list2 .post-text:after {
		border-bottom: 30px solid #ffffff;
	}
}
.blog-style-styled_list1 .post-img a,
.blog-style-styled_list2 .post-img a,
.blog-style-timeline .post-img a {
	border: 3px solid #ffffff;
}


#footer-nav,
.custom-footer,
#colophon .gem-custom-mailchimp input[type="email"] {
	background-color: #002530;
}

.gem-dummy,
.default-background,
.sc-wrapbox-style-6 .sc-wrapbox-inner,
.gem-table thead,
.pricing-title-new,
.highlighted .pricing-title,
.gem-clients-type-carousel-grid .gem-client-item a,
.gem_client-carousel .gem-client-item a,
.before-products-list,
.shipping-calculator-form-wrap,
.woocommerce-message,
.woocommerce-info,
ul.woocommerce-error li,
#primary-menu.no-responsive > li.menu-item-cart .widget_shopping_cart_content .minicart-bottom,
.hamburger-minicart .widget_shopping_cart_content .minicart-bottom,
.woocommerce .checkout #payment .payment_methods li .payment_box,
.checkout-cart-info > td,
.woocommerce table.shop_table.order-details thead tr th,
.woocommerce #customer_login .col .login-box,
.widget_recently_viewed_products ul.product_list_widget,
.widget .searchform,
.gem-tabs-style-1 .gem_tabs_nav li,
.gem-tour-style-1 .gem_tabs_nav li,
.gem-tabs-style-1 .resp-accordion.resp-tab-active,
.gem-tour-style-1 .resp-accordion.resp-tab-active,
.gem-tabs-style-2 .resp-accordion.resp-tab-active,
.blog-style-timeline.blog-style-styled_list1 .item-post,
.blog-style-default article .post-image .gem-dummy:after,
.blog-style-default article.sticky .post-image .gem-dummy,
.widget_recent_reviews .product_list_widget .wp-post-image,
.price_slider.ui-slider.ui-slider-horizontal.ui-widget.ui-widget-content.ui-corner-all,
.post-author-block,
.digram-line-box,
#calendar_wrap,
.widget_recent_comments li,
.project_info-item-style-1,
.widget_mc4wp_widget form,
.widget_submenu  ul ul,
.widget_nav_menu  ul ul,
.widget_pages ul ul,
.widget_categories ul ul,
.widget_product_categories ul ul,
.widget_recent_entries ul,
body .testimonials-style-2-text.gem-testimonial-text,
.gem-teams-item-wrap,
.gem-picturebox-text,
body .testimonials-style-1-image,
body .testimonials-style-1-text.gem-testimonial-text,
body .testimonials-style-1-name,
body .testimonials-style-1-post,
body .testimonials-style-1-teg,
body .gem-table-style-3 tr:nth-child(odd) td:nth-child(odd),
.blog-style-masonry  .description,
.style2.gem-testimonials,
.style2.gem-testimonials.fullwidth-block  .container,
.diagram-wrapper.style-3 .digram-line-box .skill-line,
.blog-style-styled_list1 article,
.blog-style-styled_list1 .post-img a .dummy,
.blog-style-styled_list1 .sticky .post-item,
.blog-style-styled_list2 .sticky .post-item,
.blog-style-timeline .sticky .post-item,
.project-info-shortcode-style-default .project-info-shortcode-item,
.project_info-item-style-2 .project_info-item,
.gem-basic-grid-2 .vc_gitem-zone-c .vc_gitem-col,
.quickfinder-style-vertical-1 .quickfinder-item-info,
.quickfinder-style-vertical-2 .quickfinder-item-info,
.justified-style-2 .post-content-wrapper,
.blog-style-multi-author .post-item,
.project-info-shortcode-style-2 .project-info-shortcode-item,
.portfolio-item:hover .wrap > .caption,
.portfolio.background-style-gray .portfolio-item .wrap > .caption,
.products .product-inner:hover {
	background-color: #f2f2f2;
}
.portfolio-item:hover .wrap,
.portfolio-items-masonry .portfolio-item:hover .caption {
	border-bottom-color: #f2f2f2;
}
.testimonials_svg svg,
.blog-style-styled_list1 .sticky .wrap-style,
.blog-style-styled_list2 .sticky .wrap-style,
.blog-style-timeline .sticky .wrap-style,
body .testimonials-style-2-item .wrap-style,
body .testimonials-style-1-item .wrap-style,
.qf-svg-arrow-left,
.qf-svg-arrow-right,
.blog-style-multi-author .wrap-style {
	fill: #f2f2f2;
}
.blog-style-styled_list2 .sticky .wrap-style {
	stroke: #f2f2f2;
}
.gem-team-style-1 .team-person,
.gem-team-style-6 .team-person,
.gem-wrapbox-style-2,
.gem-team-style-5 .team-person:hover .image-hover,
.products .product-inner:hover {
	border-color: #f2f2f2;
}
.portfolio-item:hover .wrap > .caption .caption-sizable-content:after,
.portfolio.background-style-gray .portfolio-item .wrap > .caption .caption-sizable-content:after {
	box-shadow: 0 0 30px 45px #f2f2f2;
}

.gem-styled-color-1,
.gem-date-color,
.gem-table thead th,
.woocommerce table.shop_table.order-details thead tr th,
.gem_accordion_header a,
.gem-testimonial-name,
.post-author-avatar:after,
.comments-link a:hover,
.shipping_calculator .shipping-calculator-button:before,
.widget_calendar caption,
.widget.widget_search .gem-button,
#wp-calendar thead tr th,
.widget.widget_calendar a:hover,
.gem-teams-phone,
.resp-tab-active,
.wpb_toggle,
.vc_toggle_title h4,
.account-tittle,
.blog-style-timeline .post-time span,
.blog.blog-style-masonry article .description .tags a.active,
.widget_tweets .twitter-box .jtwt_tweet:before,
.jtwt_tweet_text.icon-twitter a,
.gem-pp-posts-date,
.gem-latest-news-title span,
.widget.widget_search .gem-button:hover,
.mc-text span,
.blog-style-styled_list1 .post-time,
.blog-style-styled_list2 .post-time,
.blog-style-multi-author .post-date,
.comment-form label em,
.comment-awaiting-moderation,
.woocommerce-before-cart .cart-short-info .items-count,
.shop_table td.product-remove .remove,
.products .product-info .price ins,
.products .product-info .price .amount,
.comment-form-rating .stars a.active,
.comment-form-rating .stars a:hover,
.woocommerce .checkout .woocommerce-billing-collumn .form-row label abbr,
.woocommerce .edit-address-form .woocommerce-billing-collumn .form-row label abbr,
.woocommerce ul.woocommerce-error li,
.woocommerce .checkout-login-box .login .form-row label .required,
.woocommerce .checkout-lost-password-box .form-row label .required,
.woocommerce #customer_login .col .login-box .form-row label .required,
body .mobile-cart .mobile-cart-header .mobile-cart-header-title:before,
body .woocommerce .woocommerce-checkout .shop_table.woocommerce-checkout-payment-total .order-total td .amount,
.read-more-link:before,
.post-related-posts h2:before,
.gem_accordion_header.ui-accordion-header-active .ui-accordion-header-icon:before,
.form-edit-adress abbr,
.post-author-info .categories a,
.post-author-info .categories,
.block-navigation-prev a:before,
.block-navigation-next a:after,
h4.wpb_toggle_title_active:after,
.vc_toggle_active .vc_toggle_title:after,
.resp-accordion.resp-tab-active:after,
.portfolio.hover-zooming-blur .portfolio-item .image .overlay .links .caption .info a,
.portfolio.hover-zooming-blur .portfolio-item .image .overlay .links .caption .info span.sep,
.blog.blog-style-masonry article .description .info .more-link:before,
.portfolio.hover-new-horizontal-sliding .portfolio-item .image .overlay .links .caption .info a,
.portfolio.hover-new-horizontal-sliding .portfolio-item .image .overlay .links .caption .info span.sep,
.version-new.news-grid.hover-new-horizontal-sliding .portfolio-item .links .caption .grid-post-meta .grid-post-meta-comments-likes a,
.portfolio.hover-default-horizontal-sliding .portfolio-item .image .overlay .links .caption .info a,
#colophon .widget_nav_menu ul.menu li.menu-item-current > a,
#colophon .widget_submenu > div > ul li.menu-item-current > a,
#colophon .widget_nav_menu ul.menu li.menu-item-active > a,
#colophon .widget_submenu > div > ul li.menu-item-active > a {
	color: #d3950d;
}

.portfolio.hover-default-horizontal-sliding .portfolio-item .image .overlay .links .caption .info span.sep {
	border-left-color: #d3950d;
}

.portfolio-filters a.active,
.post-tags-list a:hover,
.portfolio .portfolio-item .caption .caption-separator,
.portfolio .portfolio-item .caption .caption-separator-line:after,
.portfolio .portfolio-item .caption .caption-separator-line-hover:after,
body.search .page-title-block,
.widget_pages > ul li.current_page_item  > a:before,
.widget_pages > ul  li li .current_page_item > a:before,
.widget_nav_menu li li .current-menu-item  > a:before,
.widget_submenu li li .menu-item-active  > a:before,
.widget_nav_menu .current-menu-ancestor > a:before,
.widget_submenu .menu-item-active> a:before,
.widget_submenu .current-menu-ancestor > a:before,
.widget_nav_menu li li a:hover:before,
.widget_submenu li li a:hover:before,
.widget_categories li li a:hover:before,
.widget_categories .current-cat a:before,
.widget_categories > ul li.current-cat-parent > a:before,
.widget_product_categories li li a:hover:before,
.widget_product_categories .current-cat a:before,
.widget_product_categories > ul li.current-cat-parent > a:before,
.widget_pages > ul li li.page_item_has_children.current_page_ancestor > a:before,
.widget_pages  ul li li a:hover:before,
#colophon .widget_calendar td a,
body .widget .tagcloud a:hover,
.gem-table-responsive.gem-table-style-3 .tabletolist > li:nth-child(odd) .titles,
.gem-table-style-3 thead tr th:nth-child(odd),
.site-footer .widget_text ul li:before,
.page-content.content-none .searchform .searchform-submit .gem-button,
.wpcf7-form .gem-button:not(.gem-button-wpcf-custom),
.wpcf7-form input[type='submit']:not(.gem-button-wpcf-custom),
.wpcf7-form.gem-contact-form-white input[type='submit']:not(.gem-button-wpcf-custom),
.wpcf7-form.gem-contact-form-dark input[type='submit']:not(.gem-button-wpcf-custom),
body .vc_grid-container ul.vc_grid-filter li.vc_grid-filter-item.vc_active,
.gem-media-grid-2 .vc_gitem-animated-block .vc_gitem-zone-b,
.ui-slider-handle.ui-state-default.ui-corner-all,
.product .onsale,
body #primary-menu.no-responsive > li.menu-item-cart > .minicart ul li.sale .minicart-image:before,
body .hamburger-minicart > .minicart ul li.sale .minicart-image:before,
body.mobile-cart-position-top #site-header .mobile-cart ul li.sale .minicart-image:before,
.products .product-info .rating-empty,
.woocommerce-cart-form .actions .checkout-button,
.widget_shopping_cart_content .buttons a.checkout,
body .products .category-overlay-separator,
#primary-menu.no-responsive > li.menu-item-cart .widget_shopping_cart_content .buttons a.checkout,
.hamburger-minicart .widget_shopping_cart_content .buttons a.checkout,
.woocommerce ul.woocommerce-error li:before,
.widget .product_list_widget .onsale,
.shop-widget-area .onsale,
.widget_shopping_cart ul li.sale .minicart-image:before,
.gem-team-style-5 .team-person:hover .image-hover,
.blog-style-default article.sticky .sticky-label,
.blog-style-default article.sticky .sticky-label:after,
.blog-style-masonry article.sticky .sticky-label,
.blog-style-masonry article.sticky .sticky-label:before,
.blog-style-masonry article.sticky .sticky-label:after,
.blog-style-styled_list1 article.sticky .sticky-label,
.blog-style-styled_list1 article.sticky .sticky-label:before,
.blog-style-styled_list1 article.sticky .sticky-label:after,
.blog-style-styled_list2 article.sticky .sticky-label,
.blog-style-styled_list2 article.sticky .sticky-label:before,
.blog-style-styled_list2 article.sticky .sticky-label:after,
.blog-style-timeline article.sticky .sticky-label,
.blog-style-timeline article.sticky .sticky-label:before,
.blog-style-timeline article.sticky .sticky-label:after,
.justified-style-1 article.sticky .sticky-label,
.justified-style-2 article.sticky .sticky-label,
.justified-style-1 article.sticky .sticky-label:before,
.justified-style-2 article.sticky .sticky-label:before,
.justified-style-1 article.sticky .sticky-label:after,
.justified-style-2 article.sticky .sticky-label:after,
.blog-style-multi-author article.sticky .sticky-label,
.blog-style-multi-author article.sticky .sticky-label:before,
.blog-style-multi-author article.sticky .sticky-label:after,
.mc-form #mc4wp_submit:hover,
#primary-navigation #primary-menu > li.menu-item-cart > a .minicart-item-count,
#primary-navigation #primary-menu > li.menu-item-cart > a .minicart-item-count:after,
body.mobile-cart-position-top #site-header .mobile-cart .minicart-item-count,
.hamburger-minicart > a .minicart-item-count,
.quick-view-button,
body .woocommerce .checkout-signin .checkout-create-account button,
.gem-featured-posts-slider-dots a.selected > span {
	background-color: #d3950d;
}
.quick-view-button {
	background-color: rgba(211, 149, 13, 0.65);
}
.header-style-2 #primary-menu.no-responsive li li.menu-item-current > a,
.header-style-2 #primary-menu.no-responsive li li.menu-item-active > a,
.header-style-2 #primary-menu.no-responsive li li:hover > a,
.header-style-3 #primary-menu.no-responsive li li.menu-item-current > a,
.header-style-3 #primary-menu.no-responsive li li.menu-item-active > a,
.header-style-3 #primary-menu.no-responsive li li:hover > a,
.header-style-2 #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul li.menu-item-current > a,
.header-style-2 #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul li.menu-item-active > a,
.header-style-3 #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul li.menu-item-current > a,
.header-style-3 #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul li.menu-item-active > a,
.header-layout-fullwidth_hamburger.logo-position-right #primary-menu.no-responsive li li.menu-item-current > a,
.header-layout-fullwidth_hamburger.logo-position-right #primary-menu.no-responsive li li.menu-item-active > a,
.header-layout-fullwidth_hamburger.logo-position-right #primary-menu.no-responsive li li:hover > a,
.header-layout-fullwidth_hamburger.logo-position-left #primary-menu.no-responsive li li.menu-item-current > a,
.header-layout-fullwidth_hamburger.logo-position-left #primary-menu.no-responsive li li.menu-item-active > a,
.header-layout-fullwidth_hamburger.logo-position-left #primary-menu.no-responsive li li:hover > a,
.mobile-menu-layout-default .primary-navigation.responsive ul > li.menu-item-current > a,
.mobile-menu-layout-default .primary-navigation.responsive ul > li.menu-item.menu-item-active a,
body .widget .tagcloud a:hover,
.gem-media-grid .vc_gitem-animated-block .vc_gitem-zone-b .vc_separator .vc_sep_holder .vc_sep_line,
.products .product .product-info .product-rating .empty-rating:before {
	border-color: #d3950d;
}
body .woocommerce-checkout-one-page #customer_details > div:after {
	border-bottom-color: #d3950d;
}

.gallery-item .overlay .subtitle a,
.portfolio.background-style-gray .portfolio-item:hover .wrap > .caption .zilla-likes:hover,
.single-product-content .price del,
.shop_table td.product-subtotal,
.page-content.content-none .searchform input,
.woocommerce table.shop_table.order-details tr.cart_item td.product-name .product-info .styled-subtitle,
.woocommerce table.shop_table.order-details tr.order_item td.product-name .product-info .styled-subtitle,
.woocommerce table.shop_table.order-details tr.cart_item td.product-name .product-info h4 a,
.woocommerce table.shop_table.order-details tr.order_item td.product-name .product-info h4 a,
.widget_shopping_cart_content .quantity,
.woocommerce .checkout_coupon .styled-subtitle,
.woocommerce table.shop_table.order-details tr.cart_item td.product-total .amount,
.woocommerce table.shop_table.order-details tr.order_item td.product-total .amount,
.woocommerce .gem-products-price ins,
.gem-products-price > span,
.widget-area .reviewer,
.widget_shopping_cart_content .quantity,
.widget_shopping_cart_content .total .amount,
.zilla-likes.active,
.zilla-likes.active:hover,
body .pricing-table-style-1 .pricing-price-subtitle,
.post-tags-list a,
.version-default.news-grid.hover-default-horizontal-sliding .portfolio-item .image .links .caption .grid-post-meta a.icon.share:before,
.version-default.news-grid.hover-default-horizontal-sliding .portfolio-item .links .caption .grid-post-meta .comments-link a,
.version-default.news-grid.hover-default-horizontal-sliding .portfolio-item .links .caption .grid-post-meta .portfolio-sharing-pane .socials-item,
.version-default.news-grid.hover-default-zooming-blur .portfolio-item .image .overlay .links .caption .grid-post-meta a.icon.share:before,
.version-default.news-grid.hover-default-zooming-blur .portfolio-item .image .overlay .links .portfolio-sharing-pane .socials-item,
.version-default.news-grid.hover-default-zooming-blur .portfolio-item .links .caption .grid-post-meta .comments-link a {
	color: #3197cd;
}
.version-default.news-grid.hover-default-horizontal-sliding .portfolio-item .image .links .caption .grid-post-meta .comments-link + .post-meta-likes,
.version-default.news-grid.hover-default-horizontal-sliding .portfolio-item .image .links .caption .grid-post-meta .grid-post-share + .grid-post-meta-comments-likes .comments-link,
.version-default.news-grid.hover-default-horizontal-sliding .portfolio-item .image .links .caption .grid-post-meta .grid-post-share + .grid-post-meta-comments-likes .post-meta-likes,
.version-default.news-grid.hover-default-zooming-blur .portfolio-item .image .links .caption .grid-post-meta .comments-link + .post-meta-likes,
.version-default.news-grid.hover-default-zooming-blur .portfolio-item .image .links .caption .grid-post-meta .grid-post-share + .grid-post-meta-comments-likes .comments-link,
.version-default.news-grid.hover-default-zooming-blur .portfolio-item .image .links .caption .grid-post-meta .grid-post-share + .grid-post-meta-comments-likes .post-meta-likes {
	border-left-color: #3197cd;
}
.widget.widget_search .gem-button,
.widget_nav_menu li li a:before,
.widget_submenu li li a:before,
.widget_pages li li a:before,
.widget_categories li li a:before,
.widget_product_categories li li a:before,
#wp-calendar caption,
body .vc_grid-container ul.vc_grid-filter li.vc_grid-filter-item:hover {
	background-color: #3197cd;
}
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-page a {
	border: 2px solid #3197cd;
}
body .widget .tagcloud a{
	border: 1px solid #3197cd;
}

.zilla-likes,
span.req,
.widget-title:after,
#wp-calendar thead tr th:last-child,
.widget_pages > ul  li li li.current_page_item > a,
.widget_nav_menu li li li a:hover,
.widget_nav_menu li li li.current-menu-item > a,
.widget_submenu li li li.menu-item-active > a,
.widget_submenu li li li a:hover,
.widget_pages ul li li li a:hover,
.widget_categories li li li a:hover,
.widget.widget_categories > ul li li.current-cat > a,
.widget_product_categories li li li a:hover,
.widget.widget_product_categories > ul li li.current-cat > a,
.products .product-bottom .yith-wcwl-wishlistexistsbrowse a:before,
.portfolio.products .portfolio-item .image .overlay .portfolio-icons.product-bottom .yith-wcwl-wishlistexistsbrowse a:before,
.news-grid.portfolio.hover-zooming-blur .zilla-likes.active,
.version-new.news-grid.hover-new-zooming-blur .portfolio-item .links .caption .grid-post-meta .post-meta-likes a,
.version-default.news-grid.hover-default-zooming-blur .portfolio-item .links .caption .grid-post-meta .post-meta-likes a,
.version-new.news-grid .portfolio-item .wrap > .caption .grid-post-meta .zilla-likes:before,
.version-default.news-grid.hover-default-horizontal-sliding .portfolio-item .links .caption .grid-post-meta .grid-post-meta-comments-likes .zilla-likes {
	color: #397985;
}
.widget_pages > ul  li li li.current_page_item > a:before,
.widget_nav_menu li li li.current-menu-item > a:before,
.widget_submenu li li li.menu-item-active > a:before,
.widget_nav_menu li li li a:hover:before,
.widget_submenu li li li a:hover:before,
.widget_pages li li li a:hover:before,
.widget_categories li li li a:hover:before,
.widget_product_categories li li li a:hover:before,
.widget_nav_menu div ul li li li a:hover:before,
.widget_submenu div ul li li li a:hover:before,
.widget_pages  ul li li li a:hover:before,
.widget_categories ul li li li a:hover:before,
.widget.widget_categories > ul li.current-cat > a:before,
.widget_product_categories ul li li li a:hover:before,
.widget.widget_product_categories > ul li.current-cat > a:before,
#primary-menu.no-responsive > li.megamenu-enable > ul > li .mega-label {
	background-color: #397985;
}

#primary-menu.no-responsive .vertical-minisearch .sf-submit-icon:after,
body .woocommerce .woocommerce-cart-form .actions .submit-buttons .checkout-button-button .checkout-button,
body .woocommerce .payment_methods .radio-sign:before,
body .product .new-label,
body .products .product-category a:hover .category-overlay,
.related-posts-carousel .related-posts-navigation .gem-button.related-posts-prev:hover,
.related-posts-carousel .related-posts-navigation .gem-button.related-posts-next:hover {
	background-color: #54b4c7;
}
body .woocommerce .woocommerce-cart-form .actions .submit-buttons .checkout-button-button .checkout-button:hover,
.related-posts-carousel .related-posts-navigation .gem-button.related-posts-prev:hover,
.related-posts-carousel .related-posts-navigation .gem-button.related-posts-next:hover {
	border-color: #54b4c7;
}
.zilla-likes:hover,
body .woocommerce .woocommerce-cart-form .actions .submit-buttons .checkout-button-button .checkout-button:hover,
.news-grid.portfolio.hover-default .zilla-likes:hover,
.news-grid.portfolio.hover-vertical-sliding .zilla-likes:hover,
.news-grid.portfolio.hover-gradient .zilla-likes:hover,
.news-grid.portfolio.hover-circular .zilla-likes:hover,
.news-grid.portfolio.hover-default .comments-link a:hover,
.news-grid.portfolio.hover-vertical-sliding .comments-link a:hover,
.news-grid.portfolio.hover-gradient .comments-link a:hover,
.news-grid.portfolio.hover-circular .comments-link a:hover,
.version-new.news-grid .portfolio-item .wrap > .caption .author,
.gem-featured-posts-slider-nav.style-icon-1 a:hover,
.gem-featured-posts-slider-nav.style-icon-2 a:hover,
.gem-featured-posts-slider-nav.style-icon-3 a:hover {
	color: #54b4c7;
}
.version-new.news-grid.portfolio.hover-new-default .portfolio-item .image .links .caption .info,
.version-new.news-grid.portfolio.hover-new-zooming-blur .portfolio-item .image .links .caption .info,
.version-new.news-grid.portfolio.hover-new-vertical-sliding .portfolio-item .image .links .caption .info {
	background-color: rgba(84, 180, 199, 0.7);
}

.gem-default-divider {
	border-top: 1px solid #397985;
}
.blog-style-default article:not(.sticky) {
	border-bottom: 1px solid #397985;
}
.skill-line {
	background-color: #397985;
}

.bordered-box,
.portfolio.columns-1.background-style-white .portfolio-item .wrap > .caption,
.gem-table-responsive .tabletolist.nrh > li > ul,
*:not(.gem-table):not(.cart_totals) > table:not(.shop_table):not(.group_table):not(.variations) td,
*:not(.gem-table):not(.cart_totals) > table:not(.shop_table):not(.group_table):not(.variations) th,
.products .product-inner,
.widget_recent_reviews ul.product_list_widget li,
.widget_shopping_cart_content ul.product_list_widget li,
form.cart .quantity .minus,
form.cart .quantity .plus,
.shop_table .quantity .minus,
.shop_table .quantity .plus,
.woocommerce-cart-form.responsive .cart-item,
.form-edit-adress-fields,
.woocommerce-cart-form.responsive .coupon,
.woocommerce .checkout_coupon,
.woocommerce .checkout-login-box .login-message-box,
.triangle-with-shadow:after,
.gem-tabs-style-1 .resp-tabs-container,
.gem-tabs-style-1 .gem_tabs_nav li,
.gem-tour-style-1 > .gem_tour_tabs_wrapper,
.gem-tour-style-1 .gem_tabs_nav li,
.gem-tabs-style-2 .resp-accordion,
.blog-style-timeline .post-text,
.widget_layered_nav ul,
.gem-table,
.gem-table-style-1 td,
.blog-style-styled_list1 .post-item,
.blog-style-styled_list2 .post-item,
.blog-style-timeline .post-item,
.gem-basic-grid .vc_grid-item-mini,
.gem-media-grid-2 .vc_grid-item-mini,
.justified-style-1 .post-content-wrapper,
.widget-area .widget_recently_viewed_products .gem-products-image,
form.cart .quantity .qty {
	border: 1px solid #f2f2f2;
}
.blog-style-styled_list1 .wrap-style,
.blog-style-styled_list1 .sticky .wrap-style,
.blog-style-styled_list2 .wrap-style,
.blog-style-timeline .wrap-style {
	stroke: #f2f2f2;
}
.gem-wrapbox-style-1,
.gem-wrapbox-style-4,
.gem-wrapbox-style-5,
.gem-wrapbox-style-6,
.gem-wrapbox-style-7,
.gem-wrapbox-style-8,
.gem-wrapbox-style-11,
.gem-wrapbox-style-10,
.gem-wrapbox-style-3,
body .woocommerce .shop_table tbody tr td,
body .woocommerce .woocommerce-checkout .shop_table.woocommerce-checkout-payment-total th,
body .woocommerce .woocommerce-checkout .shop_table.woocommerce-checkout-payment-total td,
body .woocommerce .woocommerce-checkout .shop_table tfoot > tr > td,
body .woocommerce .cart_totals table th,
body .woocommerce .cart_totals table td,
body .woocommerce .woocommerce-checkout #order_review .woocommerce-checkout-payment .payment_methods li,
body .woocommerce-checkout-one-page #order_review table tbody th,
body .woocommerce-checkout-one-page #order_review table tbody td,
body #primary-menu.no-responsive > li.menu-item-cart > .minicart,
body .hamburger-minicart > .minicart {
	border-color: #f2f2f2;
}
.gem-table-style-2 td,
.gem-table-style-2 thead {
	border: 2px solid #f2f2f2;
}
.shop_table .quantity .qty,
.woocommerce table.shop_table.order-details,
.woocommerce table.shop_table.order-details tr.cart_item,
.woocommerce table.shop_table.order-details tr.order_item,
.widget .product-dummy-wrapper,
.post-related-posts,
.sidebar .widget .gem-contacts-item.gem-contacts-address,
.gem-tabs-style-2 .resp-tabs-container,
.gem-tabs-style-1 .resp-accordion.resp-tab-active,
.gem-tour-style-1 .resp-accordion.resp-tab-active,
.widget_submenu > div > ul,
.widget_nav_menu > div > ul,
.widget.widget_pages > ul,
.widget_categories > ul,
.widget_product_categories > ul,
.widget_nav_menu ul.menu,
.portfolio-item .wrap,
.portfolio.news-grid.background-style-transparent .portfolio-item .wrap,
.Custom_Recent_Posts ul li,
.Custom_Popular_Posts ul li,
.widget_recent_entries li,
.widget_meta li,
.gem-latest-news,
.gem-latest-news ul,
.widget .gem-products-content,
.widget-area .gem-products-image,
.widget.widget_shopping_cart ul li .minicart-image,
.project_info-item-style-1  .project_info-item.iconed,
.widget_tweets .twitter-box .jtwt_tweet,
.blog-style-default article:not(.sticky),
.project-info-shortcode-style-2 .project-info-shortcode-item,
.single-product-content .rating-divider {
	border-bottom: 1px solid #f2f2f2;
}

.gem_accordion_section + .gem_accordion_section,
.shop_table .quantity .qty,
.gem-table .shop_table tr + tr td,
.cart_totals table tr + tr td,
.cart_totals table tr + tr th,
.order-totals table tr + tr td,
.order-totals table tr + tr th,
.woocommerce table.checkout-cart-info-table tr,
.mobile-cart-position-top #site-header .mobile-cart .widget_shopping_cart_content ul.cart_list > li,
.gem-tabs-style-2 .resp-tabs-container,
.gem-tour-style-2 .gem_tabs_nav li + li,
.gem-tour-style-1 .resp-accordion,
.gem-tour-style-2 .resp-accordion,
.gem-tabs-style-1 .resp-accordion,
.wpb_toggle_content + .wpb_toggle,
.vc_toggle + .vc_toggle .vc_toggle_title,
.widget .product-dummy-wrapper,
.widget_layered_nav ul li,
.widget_submenu li,
.widget_nav_menu li,
.widget.widget_pages li,
.widget_categories li,
.widget_product_categories li,
.widget_recently_viewed_products ul.product_list_widget li + li,
.widget .gem-products-content,
.widget-area .gem-products-image,
.widget.widget_shopping_cart ul li .minicart-image,
#colophon .widget_nav_menu ul.menu > li > ul,
#colophon .widget_submenu > div > ul > li > ul,
.single-product-content .rating-divider {
	border-top: 1px solid #f2f2f2;
}
.widget_submenu > div > ul,
.widget_nav_menu > div > ul,
.widget.widget_pages > ul,
.widget_categories > ul,
.widget_product_categories > ul,
.gem-tour-style-2 .resp-tabs-container,
.gem-tabs-style-2 .gem_tabs_nav li + li,
.widget-area .gem-products-image,
.widget.widget_shopping_cart ul li .minicart-image,
#colophon .widget_nav_menu ul.menu > li > ul,
#colophon .widget_submenu > div > ul > li > ul {
	border-left: 1px solid #f2f2f2;
}
.widget_submenu > div > ul,
.widget_nav_menu > div > ul,
.widget.widget_pages > ul,
.widget_categories > ul,
.widget_product_categories > ul,
.widget .gem-products-content,
.gem-table-style-2 .tabletolist .row_headers,
#colophon .widget_nav_menu ul.menu > li > ul,
#colophon .widget_submenu > div > ul > li > ul {
	border-right: 1px solid #f2f2f2;
}
.portfolio-filters a,
body .testimonials-style-2-item,
.widget_calendar td a,
.widget-area .widget.widget_search form,
form.cart .quantity .minus,
form.cart .quantity .plus,
.shop_table .quantity .minus,
.shop_table .quantity .plus,
.blog-style-timeline article:after,
#colophon .widget_calendar td a:hover,
.diagram-wrapper.style-2 .digram-line-box .skill-line,
.blog-style-styled_list1 .sticky,
.blog-style-styled_list1 .sticky .post-img a .dummy,
.widget .gem-dummy,
.quickfinder-style-vertical .quickfinder-item:first-child .quickfinder-item-connector.connector-bot,
body .quickfinder-item .quickfinder-item-connector,
.sharing-popup,
.widget-gem-portfolio-item.gem-portfolio-dummy,
.blog-style-timeline article:before,
.post-tags-list a {
	background-color: #f2f2f2;
}
.sharing-popup .sharing-styled-arrow {
	fill: #f2f2f2;
}
@media (min-width: 768px) {
	.blog-style-timeline .item-post .post-text:before {
		border-right: 20px solid #f2f2f2;
	}
}
.blog-style-styled_list1 .post-img,
.blog-style-styled_list2 .post-img,
.blog-style-timeline .post-img {
	border: 3px solid #f2f2f2;
}
@media (max-width: 768px) {
	.gem-table-responsive .tabletolist > li span {
		border-top: 1px solid #f2f2f2;
	}
	.gem-table-responsive .tabletolist > li {
		border: 1px solid #f2f2f2;
	}
	.gem-table-responsive .tabletolist .row_headers {
		border-right: 1px solid #f2f2f2;
	}
	.gem-table-responsive.gem-table-style-2 .tabletolist > li {
		border: 2px solid #f2f2f2;
	}
	.gem-table-responsive.gem-table-style-2 .tabletolist > li span {
		border-top: 2px solid #f2f2f2;
	}
	.gem-table-responsive.gem-table-style-2 .tabletolist .row_headers,
	.gem-table-responsive.gem-table-style-2 .tabletolist .row_headers {
		border-right: 2px solid #f2f2f2;
	}
	.blog-style-timeline .item-post .post-text:before,
	.blog-style-timeline .post-text:before,
	.blog-style-timeline.blog-style-styled_list1 .post-text:before,
	.blog-style-timeline.blog-style-styled_list2 .post-text:before {
		border-bottom: 30px solid #f2f2f2;
	}
}
.comments-title:before,
.comment-reply-title:before {
	color: #f2f2f2;
}
/*
#primary-navigation.responsive #primary-menu li > a,
#primary-navigation.responsive #primary-menu li > span.megamenu-column-header > a {
	font-family: 'Montserrat';
}
*/
#primary-navigation.responsive #primary-menu li > a,
#primary-navigation.responsive #primary-menu li > span.megamenu-column-header > a {
	font-weight: 700;
}

#primary-navigation.responsive #primary-menu li > a,
#primary-navigation.responsive #primary-menu li > span.megamenu-column-header > a {
	font-size: 24px;
}

#primary-navigation.responsive #primary-menu li > a,
#primary-navigation.responsive #primary-menu li > span.megamenu-column-header > a {
	line-height: 48px;
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive .mobile-menu-slide-wrapper,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive .mobile-menu-slide-wrapper,
body .menu-overlay.mobile {
	background-color: #212331;
}

#primary-navigation.responsive #primary-menu > li > a,
#primary-navigation.responsive #primary-menu > li > .menu-item-parent-toggle,
#primary-navigation.responsive ul.dl-submenu.level1 > li > a,
.primary-navigation.responsive .minisearch .sf-input,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-search > .minisearch input[type="text"],
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-search > .minisearch .sf-submit-icon:before,
.mobile-cart-position-top .menu-overlay.mobile.active + #site-header .mobile-cart > a:before {
	color: #ffffff;
}
.primary-navigation.responsive .minisearch .sf-input::placeholder,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-search > .minisearch input[type="text"]::placeholder {
	color: #ffffff;
}
.primary-navigation.responsive .minisearch .sf-input::-webkit-input-placeholder,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-search > .minisearch input[type="text"]::-webkit-input-placeholder{
	color: #ffffff;
}

#page #site-header.mobile-menu-layout-overlay .header-main #primary-navigation.overlay-active:not(.close) .menu-toggle .menu-line-1,
#page #site-header.mobile-menu-layout-overlay .header-main #primary-navigation.overlay-active:not(.close) .menu-toggle .menu-line-2,
#page #site-header.mobile-menu-layout-overlay .header-main #primary-navigation.overlay-active:not(.close) .menu-toggle .menu-line-3,
#page #site-header.mobile-menu-layout-overlay .header-main #perspective-menu-buttons.overlay-active:not(.close) .menu-toggle .menu-line-1,
#page #site-header.mobile-menu-layout-overlay .header-main #perspective-menu-buttons.overlay-active:not(.close) .menu-toggle .menu-line-2,
#page #site-header.mobile-menu-layout-overlay .header-main #perspective-menu-buttons.overlay-active:not(.close) .menu-toggle .menu-line-3 {
	background-color: #ffffff;
}

.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-search > .minisearch input[type="text"] {
	border-color: #ffffff;
}


#primary-navigation.responsive #primary-menu > li.menu-item-current > a,
#primary-navigation.responsive #primary-menu > li.menu-item-active > a,
#primary-navigation.responsive #primary-menu > li.opened > a,
#primary-navigation.responsive #primary-menu > li.menu-item-current > .menu-item-parent-toggle,
#primary-navigation.responsive #primary-menu > li.menu-item-active > .menu-item-parent-toggle,
#primary-navigation.responsive #primary-menu > li.opened > .menu-item-parent-toggle,
#primary-navigation.responsive ul.dl-submenu.level1 > li.menu-item-current > a,
#primary-navigation.responsive ul.dl-submenu.level1 > li.menu-item-active > a,
#primary-navigation.responsive #primary-menu > li.menu-overlay-item-open > a,
#primary-navigation.responsive #primary-menu > li.menu-overlay-item-open > .menu-item-parent-toggle {
	color: #3197cd;
}


#primary-navigation.responsive #primary-menu > li > ul > li > a,
#primary-navigation.responsive #primary-menu > li > ul > li > .menu-item-parent-toggle,
#primary-navigation.responsive #primary-menu > li > ul > li > span.megamenu-column-header > a,
#primary-navigation.responsive ul.dl-submenu.level2 > li > a,
#primary-navigation.responsive ul.dl-submenu.level2 > li > span.megamenu-column-header > a {
	color: #ffffff;
}


#primary-navigation.responsive #primary-menu > li > ul > li.menu-item-current > a,
#primary-navigation.responsive #primary-menu > li > ul > li.menu-item-active > a,
#primary-navigation.responsive #primary-menu > li > ul > li.opened > a,
#primary-navigation.responsive #primary-menu > li > ul > li.menu-item-current > .menu-item-parent-toggle,
#primary-navigation.responsive #primary-menu > li > ul > li.menu-item-active > .menu-item-parent-toggle,
#primary-navigation.responsive #primary-menu > li > ul > li.opened > .menu-item-parent-toggle,
#primary-navigation.responsive #primary-menu > li > ul > li.menu-item-current > span.megamenu-column-header > a,
#primary-navigation.responsive #primary-menu > li > ul > li.menu-item-active > span.megamenu-column-header > a,
#primary-navigation.responsive #primary-menu > li > ul > li.opened > span.megamenu-column-header > a,
#primary-navigation.responsive ul.dl-submenu.level2 > li.menu-item-current > a,
#primary-navigation.responsive ul.dl-submenu.level2 > li.menu-item-active > a,
#primary-navigation.responsive ul.dl-submenu.level2 > li.menu-item-current > span.megamenu-column-header > a,
#primary-navigation.responsive ul.dl-submenu.level2 > li.menu-item-active > span.megamenu-column-header > a,
#primary-navigation.responsive #primary-menu > li > ul > li.menu-overlay-item-open > a,
#primary-navigation.responsive #primary-menu > li > ul > li.menu-overlay-item-open > span.megamenu-column-header > a,
#primary-navigation.responsive #primary-menu > li > ul > li.menu-overlay-item-open > .menu-item-parent-toggle {
	color: #3197cd;
}


#primary-navigation.responsive #primary-menu > li > ul > li > ul li > a,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li > .menu-item-parent-toggle,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li > span.megamenu-column-header > a,
#primary-navigation.responsive ul.dl-submenu.level3 > li > a {
	color: #ffffff;
}


#primary-navigation.responsive #primary-menu > li > ul > li > ul li.menu-item-current > a,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.menu-item-active > a,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.opened > a,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.menu-item-current > .menu-item-parent-toggle,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.menu-item-active > .menu-item-parent-toggle,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.opened > .menu-item-parent-toggle,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.menu-item-current > span.megamenu-column-header > a,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.menu-item-active > span.megamenu-column-header > a,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.opened > span.megamenu-column-header > a,
#primary-navigation.responsive ul.dl-submenu.level3 > li.menu-item-current > a,
#primary-navigation.responsive ul.dl-submenu.level3 > li.menu-item-active > a,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.menu-overlay-item-open > a,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.menu-overlay-item-open > span.megamenu-column-header > a,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.menu-overlay-item-open > .menu-item-parent-toggle {
	color: #3197cd;
}




#primary-navigation.responsive .mobile-menu-slide-wrapper .mobile-menu-slide-close:before,
#primary-navigation.responsive .mobile-menu-slide-wrapper .mobile-menu-slide-close:after {
	background-color: #3197cd;
}

#primary-menu.no-responsive > li > a {
	color: #00475d;
}


#primary-menu.no-responsive > li:hover > a {
	color: #3197cd;
}
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default > ul li > a:hover,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default > ul li.menu-item-current > a,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default > ul li.menu-item-active > a {
	background-color: #3197cd;
}


#primary-menu.no-responsive > li.menu-item-current > a,
#primary-menu.no-responsive > li.menu-item-active > a {
	color: #3197cd;
}

.header-style-1 #primary-menu.no-responsive > li.menu-item-current > a,
.header-style-1 #primary-menu.no-responsive > li.menu-item-active > a {
	background-color: #3197cd;
}
.header-style-3 #primary-menu.no-responsive > li.menu-item-current > a,
.header-style-3 #primary-menu.no-responsive > li.menu-item-active > a,
.header-style-4 #primary-menu.no-responsive > li.menu-item-current > a,
.header-style-4 #primary-menu.no-responsive > li.menu-item-active > a,
.header-style-3.header-layout-fullwidth_hamburger #primary-menu.no-responsive > li.menu-item-current > a,
.header-style-3.header-layout-fullwidth_hamburger #primary-menu.no-responsive > li.menu-item-active > a,
.header-style-4.header-layout-fullwidth_hamburger #primary-menu.no-responsive > li.menu-item-current > a,
.header-style-4.header-layout-fullwidth_hamburger #primary-menu.no-responsive > li.menu-item-active > a {
	border: 2px solid #3197cd;
}

#primary-menu.no-responsive > li > ul > li > a,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul li > a {
	color: #397985;
}

#primary-menu.no-responsive > li > ul > li > a,
#primary-menu.no-responsive > li.megamenu-enable > ul,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul li > a,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul li > span.megamenu-column-header > a,
.header-style-2 .minisearch .sf-input,
.header-style-3 .minisearch .sf-input,
.header-style-1 .minisearch,
.header-style-4 .minisearch {
	background-color: #f4f6f7;
}

#primary-menu.no-responsive > li > ul > li:hover > a,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul li:hover > a {
	color: #323031;
}

#primary-menu.no-responsive > li > ul > li:hover > a,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul li:hover > a {
	background-color: #ffffff;
}

#primary-menu.no-responsive > li > ul > li.menu-item-current > a,
#primary-menu.no-responsive > li > ul > li.menu-item-active > a,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul li.menu-item-current > a,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul li.menu-item-active > a,
.minisearch .sf-submit-icon:before,
.minisearch .sf-input {
	color: #323031;
}

#primary-menu.no-responsive > li > ul > li.menu-item-current > a,
#primary-menu.no-responsive > li > ul > li.menu-item-active > a,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul li.menu-item-current > a,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul li.menu-item-active > a,
.header-style-2 .minisearch,
.header-style-3 .minisearch,
.header-style-1 .minisearch .sf-input,
.header-style-4 .minisearch .sf-input {
	background-color: #ffffff;
}

#primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header a,
#primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header a.mega-no-link {
	color: #323031;
}

#primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header a:hover {
	color: #3197cd;
}

#primary-menu.no-responsive > li.megamenu-enable > ul > li.menu-item-active > span.megamenu-column-header a {
	color: #3197cd;
}

#primary-menu.no-responsive > li li li > a {
	color: #397985;
}

#primary-menu.no-responsive > li li li > a {
	background-color: #ffffff;
}

#primary-menu.no-responsive > li li li:hover > a,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default > ul li.menu-item-current > a,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default > ul li.menu-item-active > a {
	color: #ffffff;
}

#primary-menu.no-responsive > li li li:hover > a {
	background-color: #494c64;
}

#primary-menu.no-responsive > li li li.menu-item-current > a,
#primary-menu.no-responsive > li li li.menu-item-active > a {
	color: #d3950d;
}

#primary-menu.no-responsive > li li li.menu-item-current > a,
#primary-menu.no-responsive > li li li.menu-item-active > a {
	background-color: #ffffff;
}

#primary-menu.no-responsive ul > li,
#primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header,
.minisearch,
.minisearch .sf-input {
	border-top: 1px solid #dfe5e8;
}
#primary-menu.no-responsive ul,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li,
.minisearch,
.minisearch .sf-input {
	border-bottom: 1px solid #dfe5e8;
	border-left: 1px solid #dfe5e8;
	border-right: 1px solid #dfe5e8;
}
#primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header {
	border-bottom: 1px solid #dfe5e8;
}
#primary-menu.no-responsive > li.megamenu-enable > ul {
	border-top: 1px solid #dfe5e8;
}
#primary-menu.no-responsive ul ul {
	margin-top: -1px;
}

#site-header:not(.shrink) .header-colors-light:not(.header-layout-fullwidth_hamburger):not(.header-layout-overlay) #primary-menu.no-responsive > li > a {
	color: #00475d;
}

#site-header:not(.shrink) .header-colors-light:not(.header-layout-fullwidth_hamburger) #primary-menu.no-responsive > li:hover > a {
	color: #3197cd;
}

#site-header:not(.shrink) .header-colors-light:not(.header-layout-fullwidth_hamburger):not(.header-layout-overlay) #primary-menu.no-responsive > li.menu-item-current > a,
#site-header:not(.shrink) .header-colors-light:not(.header-layout-fullwidth_hamburger):not(.header-layout-overlay) #primary-menu.no-responsive > li.menu-item-active > a {
	color: #3197cd;
}
#site-header:not(.shrink) .header-colors-light.header-style-3:not(.header-layout-overlay) #primary-menu.no-responsive > li.menu-item-current > a,
#site-header:not(.shrink) .header-colors-light.header-style-3:not(.header-layout-overlay) #primary-menu.no-responsive > li.menu-item-active > a,
#site-header:not(.shrink) .header-colors-light.header-style-4:not(.header-layout-overlay) #primary-menu.no-responsive > li.menu-item-current > a,
#site-header:not(.shrink) .header-colors-light.header-style-4:not(.header-layout-overlay) #primary-menu.no-responsive > li.menu-item-active > a {
	border: 2px solid #3197cd;
}


	body .menu-overlay {
		background-color: #212331;
	}

.header-layout-overlay #primary-menu.no-responsive > li > a,
.header-layout-overlay #primary-navigation #primary-menu.no-responsive > li li > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li > a,
.page-additional-menu.header-layout-overlay .primary-navigation .nav-menu.no-responsive > li li > a,
.header-layout-overlay #primary-navigation #primary-menu.no-responsive li .menu-item-parent-toggle:before,
.page-additional-menu.header-layout-overlay #primary-navigation #primary-menu.no-responsive li .menu-item-parent-toggle:before,
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-search > .minisearch input[type="text"],
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-search > .minisearch .sf-submit-icon:before {
	color: #ffffff;
}
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-search > .minisearch input[type="text"]::placeholder {
	color: #ffffff;
}
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-search > .minisearch input[type="text"]::-webkit-input-placeholder {
	color: #ffffff;
}
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-search > .minisearch input[type="text"] {
	border-color: #ffffff;
}
#primary-navigation.overlay-active .overlay-toggle > span {
	background-color: #ffffff;
}



.hamburger-minicart > a,
.hamburger-minicart:hover > a {
	color: #323031;
}
#primary-navigation .hamburger-toggle > span,
#primary-navigation .overlay-toggle > span,
#primary-navigation.overlay-active.close .overlay-toggle > span,
#perspective-menu-buttons.primary-navigation .perspective-toggle span,
#page.vertical-header .vertical-toggle > span,
#thegem-perspective #primary-navigation .perspective-menu-close:before,
#thegem-perspective #primary-navigation .perspective-menu-close:after {
	background-color: #323031;
}

#site-header:not(.shrink) .header-colors-light .hamburger-minicart > a,
#site-header:not(.shrink) .header-colors-light .hamburger-minicart:hover > a {
	color: #323031;
}
#site-header:not(.shrink) .header-colors-light #primary-navigation .hamburger-toggle > span,
#site-header:not(.shrink) .header-colors-light #primary-navigation:not(.overlay-active) .overlay-toggle > span,
#site-header:not(.shrink) .header-colors-light #primary-navigation.overlay-active.close .overlay-toggle > span,
#site-header:not(.shrink) .header-colors-light #perspective-menu-buttons.primary-navigation .perspective-toggle span {
	background-color: #323031;
}


#primary-navigation .menu-toggle .menu-line-1,
#primary-navigation .menu-toggle .menu-line-2,
#primary-navigation .menu-toggle .menu-line-3,
#perspective-menu-buttons.primary-navigation .menu-toggle .menu-line-1,
#perspective-menu-buttons.primary-navigation .menu-toggle .menu-line-2,
#perspective-menu-buttons.primary-navigation .menu-toggle .menu-line-3 {
	background-color: #ffffff;
}
body.mobile-cart-position-top #site-header .mobile-cart > a:before {
	background-color: #ffffff;
}

#site-header:not(.shrink) .header-colors-light #primary-navigation .menu-toggle .menu-line-1,
#site-header:not(.shrink) .header-colors-light #primary-navigation .menu-toggle .menu-line-2,
#site-header:not(.shrink) .header-colors-light #primary-navigation .menu-toggle .menu-line-3,
#site-header:not(.shrink) .header-colors-light #perspective-menu-buttons.primary-navigation .menu-toggle .menu-line-1,
#site-header:not(.shrink) .header-colors-light #perspective-menu-buttons.primary-navigation .menu-toggle .menu-line-2,
#site-header:not(.shrink) .header-colors-light #perspective-menu-buttons.primary-navigation .menu-toggle .menu-line-3 {
	background-color: #ffffff;
}
body.mobile-cart-position-top #site-header:not(.shrink) .header-colors-light .mobile-cart > a:before {
	background-color: #ffffff;
}


/*
body,
.portfolio.background-style-gray .portfolio-item:hover .wrap > .caption .info,
.portfolio.background-style-dark .portfolio-item:not(:hover) .wrap > .caption .info,
.gem-gallery-hover-zooming-blur .gem-gallery-preview-carousel-wrap .gem-gallery-item .gem-gallery-caption,
.gem-gallery-grid.hover-zooming-blur .gallery-item .overlay .title,
.gem-gallery-grid.hover-zooming-blur .gallery-item .overlay .subtitle,
.widget.widget_contacts a:hover,
.widget_recent_comments a,
.digram-line-box  .skill-title,
.shipping_calculator .shipping-calculator-button,
.widget_shopping_cart_content ul.cart_list li a,
.shop-widget-area .gem-products-title a,
.woocommerce-page ul.product_list_widget li a,
.portfolio.hover-zooming-blur .portfolio-item .image .overlay .links .caption .subtitle,
.portfolio.hover-zooming-blur .portfolio-item .image .overlay .links .caption .info,
.portfolio.hover-horizontal-sliding .overlay .links .caption .info span.sep,
.version-new.news-grid.hover-new-zooming-blur .portfolio-item .image .overlay .links .caption .subtitle,
.version-new.news-grid.hover-new-zooming-blur .portfolio-item .image .overlay .links .caption .post-date,
.version-new.news-grid.hover-new-zooming-blur .portfolio-item .image .overlay .links .caption .grid-post-meta a.icon.share:before,
.version-new.news-grid.hover-new-zooming-blur .portfolio-item .image .overlay .links .portfolio-sharing-pane .socials-item,
.version-new.news-grid.hover-new-zooming-blur .portfolio-item .links .caption .grid-post-meta .comments-link a,
.version-default.news-grid.hover-default-zooming-blur .portfolio-item .image .overlay .links .caption .subtitle,
.version-default.news-grid.hover-default-zooming-blur .portfolio-item .image .overlay .links .caption .post-date,
.version-new.news-grid .portfolio-item .wrap > .caption .grid-post-meta .comments-link a,
.version-new.news-grid .portfolio-item .wrap > .caption .grid-post-meta .zilla-likes,
.version-new.news-grid .portfolio-item .wrap > .caption .grid-post-meta .grid-post-share .icon,
#colophon .jtwt_tweet:before,
#colophon .project_info-item-style-1 .project_info-item .icon,
#colophon .widget_recent_comments li:before,
#colophon .widget_recent_entries ul li:before,
#colophon .portfolio-filters a .icon,
#colophon .comment-form label:before,
#colophon .socials-sharing a:after,
#colophon .widget_archive li:before,
#colophon .widget_nav_menu ul.menu li > a:before,
#colophon .widget_submenu > div > ul li > a:before,
#colophon .widget_categories > ul li > a:before,
#colophon .widget_product_categories > ul li > a:before,
#colophon .widget_product_categories > ul li:before,
#colophon .widget_pages > ul li > a:before,
#colophon .widget_meta li:before,
#colophon .widget_rss li:before,
#colophon .project_info-item-style-2 .icon,
#colophon .gem_accordion_header,
#colophon .widget_layered_nav ul li a:before,
#colophon .widget .gem-contacts-address:before,
#colophon .widget .gem-contacts-phone:before,
#colophon .widget .gem-contacts-fax:before,
#colophon .widget .gem-contacts-email:before,
#colophon .widget .gem-contacts-website:before,
#colophon .widget_meta li:before,
#colophon .widget .mc-text.small-body,
#colophon .widget.widget_search input, #colophon #mc4wp_email,
blockquote,
.widget_price_filter .price_label span,
.gem-quote.gem-quote-style-default blockquote,
.gem-quote.gem-quote-style-1,
.wpcf7-form.gem-contact-form-white span,
.wpcf7-form.gem-contact-form-white .wpcf7-list-item-label,
.wpcf7-form.gem-contact-form-dark span,
.wpcf7-form.gem-contact-form-dark .wpcf7-list-item-label,
.blog-style-styled_list1 .post-content,
.blog-style-styled_list2 .post-content,
.blog-style-timeline .post-content,
body .thegem_testimonial a .gem-testimonial-text  {
	color: #262626;
}
#colophon .widget.widget_search .gem-button:hover,
#colophon .wpcf7 .wpcf7-form .contact-form-footer .wpcf7-submit:hover,
.wpcf7-form.gem-contact-form-dark input[type='submit']:hover {
	background-color: #262626;
}
.wpcf7-form.gem-contact-form-dark input[type='submit']:hover {
	border-color: #262626;
}

h1,
.title-h1,
.gem-counter-number,
body .gem-testimonial-item:after,
blockquote p:after,
body .testimonials-style-1-teg,
body .testimonials-style-2-teg,
.pricing-title {
	color: #262626;
}

h2,
.title-h2,
.blog-style-multi-author .post-title a,
.single-product-content .price {
	color: #262626;
}
.skill-line > div {
	background-color: #262626;
}

h3,
.title-h3,
.blog-style-styled_list1 .post-title a,
.blog-style-styled_list2 .post-title a,
.blog-style-timeline .post-title a,
.woocommerce .woocommerce-checkout-one-page #order_review table thead th {
	color: #262626;
}

h4,
.title-h4,
.team-person-name,
.widget .gem-teams-name,
body .testimonials-style-2-item .testimonials-style-2-text.gem-testimonial-text,
.diagram-wrapper.style-3 .digram-line-box .skill-title span,
.portfolio.hover-zooming-blur .portfolio-item .image .overlay .links .caption .title,
.portfolio.hover-zooming-blur .portfolio-item .image .overlay .links .portfolio-sharing-pane .socials-item,
.blog-style-masonry .entry-title.title-h4 a,
.gem-basic-grid .post-title h4.entry-title a,
.gem-basic-grid-2 .vc_gitem-zone-c .post-title a,
.justified-style-1 .entry-title.title-h4 a,
.justified-style-2 .entry-title.title-h4 a,
.portfolio.hover-gradient .portfolio-item .image .overlay .links a.icon:before,
.gem-gallery-grid.hover-gradient .gallery-item .overlay a.icon::before,
.gem-gallery-hover-gradient .gem-gallery-preview-carousel-wrap .gem-gallery-item a:after,
.news-grid.portfolio.hover-zooming-blur .portfolio-item .image .overlay .caption .title > *,
.news-grid.portfolio.hover-new-zooming-blur .portfolio-item .image .overlay .caption .title > *,
.version-new.news-grid.hover-new-zooming-blur .portfolio-item .image .links .caption .author,
.news-grid.portfolio.hover-default-zooming-blur .portfolio-item.double-item-squared:hover .image .overlay .caption .title > *,
.version-new.news-grid .portfolio-item .wrap > .caption .title a {
	color: #262626;
}
.portfolio.hover-zooming-blur .portfolio-item .image .overlay .links a.icon:before,
.portfolio.hover-new-zooming-blur .portfolio-item .image .overlay .links .portfolio-icons a.icon:before,
.portfolio.hover-new-zooming-blur .portfolio-item .gem-simple-gallery .gem-gallery-item a:after,
.portfolio.hover-default-zooming-blur .portfolio-item .image .overlay .links .portfolio-icons a.icon:before,
.portfolio.hover-default-zooming-blur .portfolio-item .gem-simple-gallery .gem-gallery-item a:after,
.gem-gallery-hover-zooming-blur .gem-gallery-preview-carousel-wrap .gem-gallery-item a:after,
.portfolio.news-grid.hover-zooming-blur .portfolio-item .gem-simple-gallery .gem-gallery-item a:after,
.gem-gallery-grid.hover-zooming-blur .gallery-item .overlay a.icon:before,
.blog article .post-featured-content > a:after,
.blog .gem-simple-gallery .gem-gallery-item a:after,
body .blog-style-styled_list1 article a.default:after,
body .blog-style-styled_list2 article a.default:after,
body .blog-style-compact article a.default:after {
	background-color: #262626;
}

h5,
.title-h5,
blockquote.wp-block-quote.is-large,
blockquote.wp-block-quote.is-style-large,
.woocommerce .woocommerce-checkout.woocommerce-checkout-one-page .shop_table.woocommerce-checkout-payment-total th,
.news-grid.hover-default .portfolio-item .image .links .caption .post-date,
.news-grid.portfolio.hover-zooming-blur .portfolio-item .image .overlay .caption .title > *,
.news-grid.portfolio.hover-new-zooming-blur .portfolio-item .image .overlay .caption .title > *,
.news-grid.portfolio.hover-default-zooming-blur .portfolio-item:hover .image .overlay .caption .title > *,
.news-grid.version-default.portfolio.hover-default-zooming-blur .portfolio-item:hover .image .overlay .caption .title > *,
.news-grid.version-default.portfolio.hover-default-zooming-blur .portfolio-item .image .overlay .links .caption .post-date,
.news-grid.portfolio.hover-gradient .portfolio-item .image .overlay .links .caption .info a,
.version-new.news-grid.hover-new-zooming-blur .portfolio-item .image .links .caption .author,
.version-default.news-grid.portfolio.hover-default-zooming-blur .portfolio-item .image .links .caption .info a {
	color: #262626;
}
.news-grid.portfolio.hover-gradient .portfolio-item .image .overlay .links .caption .info span.sep,
.version-default.news-grid.portfolio.hover-default-zooming-blur .portfolio-item .image .links .caption .info .sep {
	border-left-color: #262626;
}

h6,
.title-h6,
.widget_nav_menu > div > ul > li > a,
.widget_submenu > div > ul > li > a,
.widget_pages > ul > li > a,
.widget_categories > ul > li > a,
.widget_product_categories > ul > li > a,
.project_info-item-style-1 .project_info-item .title,
.portfolio-item:hover .wrap > .caption .title {
	color: #262626;
}
*/
/*
a:hover,
body.woocommerce-cart .custom-header .breadcrumbs span a:hover,
body.woocommerce-checkout .custom-header .breadcrumbs span a:hover,
body.woocommerce-wishlist .custom-header .breadcrumbs span a:hover,
body.woocommerce-account .page-title-block .breadcrumbs span a:hover,
body .shop_table .product-name .product-title, .shop_table .product-name .product-title a:hover {
	color: #d3950d;
}

a:active {
	color: #d3950d;
}
*/

#footer-navigation,
#footer-navigation li a,
.custom-footer,
.custom-footer li a {
	color: #ffffff;
}

.footer-site-info {
	color: #ffffff;
}

.footer-site-info a {
	color: #d3950d;
}

.page-title-block {
	background-color: #ffffff;
}

.page-title-block,
.page-title-title h1,
.page-title-excerpt {
	color: #ffffff;
}

.wpb_widgetised_column .mc4wp-form-fields,
.sidebar .mc4wp-form-fields,
.top-area {
	background-color: #262626;
}

.top-area {
	border-top: 5px solid #262626;
}

.top-area {
	border-bottom: 1px solid #363636;
}
.top-area-contacts:after,
.top-area-socials:after {
	border-right: 1px solid #363636;
}

.top-area {
	color: #ffffff;
}


.top-area a {
	color: #b2b2b2;
}

.top-area a:hover {
	color: #ffffff;
}

.top-area .gem-button {
	color: #ffffff;
}

.top-area .gem-button {
	background-color: #363636;
}

.top-area .gem-button:hover {
	color: #ffffff;
}

.top-area .gem-button:hover {
	background-color: #d3950d;
}

#footer-navigation li a {
	color: #ffffff;
}

#footer-navigation li a:hover {
	color: #00475d;
}

#footer-menu li + li:before {
	border-left-color: #002530;
	border-right-color: #002530;
}

#footer-nav {
	border-top-color: #002530;
}

.gem-news-item-date,
.related-element-info .gem-post-date,
.date-color,
.combobox-wrapper .combobox-text,
#page .woocommerce .select2-container--default .select2-selection--single .select2-selection__rendered,
.socials-sharing a.socials-item,
body .gem-testimonial-position,
.gem-testimonial-company,
.jtwt_date,
.blog.blog-style-timeline .comments,
.entry-date,
.portfolio-filters a,
.comments-link a,
.widget_recent_entries .post-date,
.post-author-info .date,
.block-date,
.block-tags .sep,
.styled-blog-meta .comments:after,
.related-element-info .date,
.login label,
.register label,
.woocommerce-password-hint,
body .woocommerce .shop_table.wishlist_table td.product-price del,
body .woocommerce .wishlist_table .product-info .product-info-content .product-price del,
body .woocommerce .woocommerce-checkout .checkout-payment .shop_table td.product-total .amount,
body .woocommerce input[type="text"].coupon-code,
body .woocommerce .woocommerce-shipping-calculator .form-row input.input-text,
body .woocommerce .checkout .form-row .input-text,
body .woocommerce .edit-address-form .form-row .input-text,
body .woocommerce .checkout .form-row textarea.input-text,
body .woocommerce .edit-address-form .form-row textarea.input-text,
body .select2-search .select2-input,
body .woocommerce .checkout-login .login .form-row .input-text,
body .woocommerce .lost_reset_password .input-text,
body .woocommerce .my-account-signup .register .form-row .input-text,
body .chosen-container-single .chosen-single span,
body .select2-container .select2-choice > .select2-chosen,
body .select2-results .select2-result-label,
body .woocommerce .chosen-container-single .chosen-single div:before,
body .woocommerce .edit-address-form .form-row .chosen-container-single .chosen-single div:before,
body .woocommerce .select2-container .select2-choice .select2-arrow:before,
body .woocommerce .woocommerce-checkout .shop_table.woocommerce-checkout-payment-total .shipping td,
body .checkout-login .login .form-row .checkout-login-remember label,
body .shop_table .product-name dl.variation,
body.woocommerce-cart .custom-header .breadcrumbs .current,
body.woocommerce-checkout .custom-header .breadcrumbs .current,
body.woocommerce-wishlist .custom-header .breadcrumbs .current,
body.woocommerce-account .page-title-block .breadcrumbs .current,
body .woocommerce .woocommerce-checkout.woocommerce-checkout-one-page #order_review .woocommerce-checkout-payment .payment_methods .payment_box,
body #primary-menu.no-responsive > li.menu-item-cart > .minicart ul li .quantity,
body #primary-menu.no-responsive > li.menu-item-cart > .minicart .total,
body #primary-menu.no-responsive > li.menu-item-cart > .minicart ul li.empty,
body .hamburger-minicart > .minicart ul li .quantity,
body .hamburger-minicart > .minicart .total,
body .hamburger-minicart > .minicart ul li.empty,
body.mobile-cart-position-top #site-header .mobile-cart ul li .quantity,
body.mobile-cart-position-top #site-header .mobile-cart .total,
body.mobile-cart-position-top #site-header .mobile-cart ul li.empty,
body #primary-menu.no-responsive > li.menu-item-cart > .minicart ul li a.remove,
body .hamburger-minicart > .minicart ul li a.remove,
body.mobile-cart-position-top #site-header .mobile-cart ul li a.remove,
.form-edit-adress label,
.teaser_grid_container .gem-post-date,
.gem-post-grid-item-date,
.page-title-style-2 .page-title-excerpt,
.blog.blog-style-masonry article .description .info .date,
.blog.blog-style-masonry article .description .info .sep,
.blog.blog-style-masonry article .description .info .comments,
.widget_categories > ul li,
.widget_product_categories > ul li,
.widget_pages > ul,
.gem-teams-position.body-small,
.widget .mc-text.small-body,
.widget .team-person-email,
.widget .jtwt_date,
.widget_recent_comments li,
.widget_price_filter .price_label,
.project_info-item-style-2 .project_info-item .title,
.mc4wp-form input[type='email'],
.gallery-caption,
#colophon .widget_tweets .jtwt_tweet a:hover,
#colophon .gem-contacts .gem-contacts-item.gem-contacts-website a:hover,
#colophon .gem-contacts .gem-contacts-item.gem-contacts-email a:hover,
#colophon .widget_calendar td a:hover,
.widget_meta li:before,
#colophon .skill-title,
#colophon .widget.widget_search .gem-button,
#colophon .wpcf7 .wpcf7-form .contact-form-footer .wpcf7-submit,
#colophon #mc4wp_submit,
#colophon .widget_meta li:before,
.gem-quote.gem-quote-style-5 blockquote,
.blog-style-masonry .post-meta-likes:hover,
.wpcf7-form .combobox-wrapper .combobox-text,
.blog-style-styled_list1 .post-date,
.blog-style-styled_list1 .post-author,
.blog-style-styled_list2 .post-date,
.blog-style-styled_list2 .post-author,
.gem-media-grid .vc_gitem-animated-block .vc_gitem-zone-b .vc_gitem-post-data-source-post_excerpt > div,
.midia-grid-item-post-author,
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-page a,
.project-info-shortcode-style-default .project-info-shortcode-item .title,
.woocommerce-variation-availability,
.gem-search-form.gem-search-form-style-dark .search-field,
.version-new.news-grid .portfolio-item .wrap > .caption .post-date,
.version-new.news-grid .portfolio-item .wrap > .caption .post-author-date-separator {
	color: #1e1e1e;
}

.version-default.news-grid.title-on-page.hover-horizontal-sliding .portfolio-item .image .links .caption .grid-post-meta .comments-link + .post-meta-likes,
.version-default.news-grid.title-on-page.hover-zooming-blur .portfolio-item .image .links .caption .grid-post-meta .comments-link + .post-meta-likes {
	border-left-color: #1e1e1e;
}

.blog.blog-style-timeline article .comments:before,
.widget .gem-contacts-email:before,
.widget .gem-contacts-phone:before,
.widget .gem-contacts-address:before,
.widget .gem-contacts-fax:before,
.top-area-search .searchform button:before,
.blog.blog-style-masonry article .description .info .comments:before,
.blog-style-default .post-image .gem-dummy:after,
.widget_product_search button,
.products .product-bottom .bottom-product-link,
.products .product-bottom .add_to_cart_button,
.products .product-bottom .added_to_cart,
.products .product-bottom .add_to_wishlist,
.products .product-bottom .yith-wcwl-wishlistaddedbrowse a,
.combobox-wrapper .combobox-button:before,
#page .woocommerce .select2-container--default .select2-selection--single .select2-selection__arrow {
	color: #99a3b0;
}

.project_info-item-style-1 .project_info-item .icon,
.widget_recent_comments li:before,
.widget_recent_entries ul li:before,
.portfolio-filters a .icon,
.comment-form label:before,
.jtwt_tweet:before,
.post-related-posts .gem-dummy:after,
.socials-sharing a:after {
	color: #b6c6c9;
}

.gem-button,
body .wp-block-button .wp-block-button__link,
.caption-bottom-line .post-footer-sharing .gem-button,
input[type='submit'],
.page-links a,
.blog-load-more button span,
.blog-load-more button span:before,
.widget a.gem-button,
#wp-calendar #prev a:after,
#wp-calendar #next a:after,
#wp-calendar caption:after,
#primary-menu.no-responsive > li.menu-item-cart .widget_shopping_cart_content .buttons a.checkout,
.hamburger-minicart .widget_shopping_cart_content .buttons a.checkout {
	color: #ffffff;
}

.gem-button:hover,
body .wp-block-button .wp-block-button__link:hover,
input[type='submit']:hover,
.caption-bottom-line .project-button .gem-button,
.portfolio.background-style-dark .portfolio-item:hover .caption-bottom-line .project-button .gem-button,
.page-links a,
.blog-load-more button span:hover,
.widget a.gem-button:hover,
#wp-calendar #prev a:hover:after,
#wp-calendar #next a:hover:after,
#primary-menu.no-responsive > li.menu-item-cart .widget_shopping_cart_content .buttons a:hover,
.hamburger-minicart .widget_shopping_cart_content .buttons a:hover {
	color: #ffffff;
}

.gem-button,
body .wp-block-button .wp-block-button__link,
.caption-bottom-line .post-footer-sharing .gem-button,
.portfolio-filters a:hover,
input[type='submit'],
.radio-sign.checked:before,
.page-links a,
.widget a.gem-button,
#wp-calendar #prev a,
#wp-calendar #next a,
.blog-load-more button,
.ui-slider-range.ui-widget-header.ui-corner-all,
.gem-blog-slider .gem-blog-slider-prev:hover,
.gem-blog-slider .gem-blog-slider-next:hover {
	background-color: #b6c6c9;
}
.comment-form .comment-author-input:after,
.comment-form .comment-email-input:after,
.comment-form .comment-url-input:after,
.team-person-socials .socials-item,
.checkbox-sign.checked:before,
body .wpcf7-form .combobox-button:before,
.wpcf7-form .phone:after,
.wpcf7-form .website:after,
.wpcf7-form .email:after,
.wpcf7-form  .name:after,
.wpcf7-form .gem-form-icon-phone:after,
.wpcf7-form .gem-form-icon-website:after,
.wpcf7-form .gem-form-icon-email:after,
.wpcf7-form .gem-form-icon-name:after,
.blog-style-styled_list1 .post-img a .dummy,
.blog-style-styled_list2 .post-img a .dummy,
.blog-style-timeline article:before,
.blog-style-timeline .post-img a .dummy {
	color: #b6c6c9;
}
.checkbox-sign,
.radio-sign {
	border: 1px solid #b6c6c9;
}

.gem-button:hover,
body .wp-block-button .wp-block-button__link:hover,
input[type='submit']:hover,
.comment-form .form-submit .gem-button.submit:hover,
.caption-bottom-line .project-button .gem-button,
.portfolio.background-style-dark .portfolio-item:hover .caption-bottom-line .project-button .gem-button,
.page-links a,
.widget a.gem-button:hover,
#wp-calendar #prev a:hover,
#wp-calendar #next a:hover,
.blog-load-more button:hover,
.page-content.content-none .searchform .searchform-submit .gem-button:hover,
.wpcf7-form.gem-contact-form-white input[type='submit']:hover,
.wpcf7-form .gem-button:not(.gem-button-wpcf-custom):hover,
.wpcf7-form input[type='submit']:not(.gem-button-wpcf-custom):hover,
.wpcf7-form.gem-contact-form-white input[type='submit']:not(.gem-button-wpcf-custom):hover,
.wpcf7-form.gem-contact-form-dark input[type='submit']:not(.gem-button-wpcf-custom):hover,
.gem-blog-slider .gem-blog-slider-prev,
.gem-blog-slider .gem-blog-slider-next,
.gem-search-form.gem-search-form-style-dark .search-field,
body .woocommerce .checkout-signin .checkout-create-account button:hover {
	background-color: #3197cd;
}
.portfolio.background-style-dark .caption-bottom-line .project-button .gem-button {
	color: #3197cd;
}

.gem-button.gem-button-style-outline,
body .woocommerce .woocommerce-cart-form .actions .submit-buttons .update-cart button:hover,
body .checkout-navigation-buttons .checkout-place-order button:hover {
	color: #d3950d;
}

.gem-button.gem-button-style-outline:hover,
#colophon.wpcf7 .wpcf7-form .contact-form-footer .wpcf7-submit:hover,
body #colophon .tagcloud a:hover,
body .woocommerce .woocommerce-cart-form .actions .submit-buttons .update-cart button,
body .checkout-navigation-buttons .checkout-place-order button {
	color: #ffffff;
}

.gem-button.gem-button-style-outline,
body .woocommerce .woocommerce-cart-form .actions .submit-buttons .update-cart button:hover,
body .checkout-navigation-buttons .checkout-place-order button:hover,
body .checkout-navigation-buttons .checkout-place-order button {
	border-color: #d3950d;
}
.gem-button.gem-button-style-outline:hover,
.comment-form .form-submit .gem-button.submit,
.portfolio-item:hover .caption-bottom-line .project-button .gem-button,
#colophon #mc4wp_submit:hover,
#colophon.wpcf7 .wpcf7-form .contact-form-footer .wpcf7-submit:hover,
body #colophon .tagcloud a:hover,
.portfolio-item-page-bottom .project-button .gem-button,
body .woocommerce .woocommerce-cart-form .actions .submit-buttons .update-cart button,
body .checkout-navigation-buttons .checkout-place-order button {
	background-color: #d3950d;
}


.widget-title,
.woocommerce .widget .gem-products-price ins,
.woocommerce .widget .gem-products-price .amount,
.widget_shopping_cart ul li .quantity,
.widget_shopping_cart .total span.amount {
	color: #3c3950;
}

.widget a {
	color: #5f727f;
}

.widget a:hover,
.gem-contacts-item.gem-contacts-email a,
.gem-contacts-item.gem-contacts-website a,
.widget_nav_menu li a:hover,
.widget_submenu li a:hover,
.widget_pages ul li a:hover,
.widget_categories > ul > li > a:hover,
.widget_product_categories > ul > li > a:hover,
.widget_nav_menu .current-menu-ancestor > a,
.widget_submenu .menu-item-active> a,
.widget_submenu .current-menu-ancestor > a,
.widget_categories > ul li.current-cat > a,
.widget_categories > ul > li.cat-parent.current-cat-ancestor:after,
.widget_categories > ul > li.cat-parent.current-cat-parent:after,
.widget_categories > ul > li.cat-parent.current-cat:after,
.widget_categories > ul li.cat-parent.current-cat-ancestor > a,
.widget_categories > ul li.cat-parent.current-cat-parent > a,
.widget_product_categories > ul li.current-cat > a,
.widget_product_categories > ul > li.cat-parent.current-cat-ancestor:after,
.widget_product_categories > ul > li.cat-parent.current-cat-parent:after,
.widget_product_categories > ul > li.cat-parent.current-cat:after,
.widget_product_categories > ul li.cat-parent.current-cat-ancestor > a,
.widget_product_categories > ul li.cat-parent.current-cat-parent > a,
.widget_pages > ul li li.current_page_item > a,
.widget_pages > ul li li.page_item_has_children.current_page_ancestor > a,
.widget_pages > ul li li.page_item_has_children.current_page_ancestor > a:after {
	color: #d3950d;
}

.widget a:active {
	color: #d3950d;
}

#colophon {
	background-color: #424242;
}

#colophon .widget-title {
	color: #feffff;
}

#colophon .widget,
body #colophon .widget .gem-testimonial-position,
#colophon .widget.widget_recent_entries .post-date {
	color: #ffffff;
}

#colophon .widget a,
#colophon .gem-contacts .gem-contacts-item:before {
	color: #ffffff;
}

#colophon .widget a:hover,
#colophon .gem-contacts .gem-contacts-item.gem-contacts-website a,
#colophon .gem-contacts .gem-contacts-item.gem-contacts-email a,
#colophon #wp-calendar #prev a:hover:after,
#colophon #wp-calendar #next a:hover:after,
#colophon  .widget_tweets .jtwt_tweet a,
#colophon  .recentcomments .comment-author-link a,
#colophon .widget .jtwt_date,
#colophon  .widget_tweets .twitter-box .jtwt_tweet:before,
#colophon .recentcomments .comment-author-link,
#colophon .comment-author-link,
#colophon .gem-latest-news-title span,
#colophon .gem-pp-posts-date,
#colophon .gem-contacts a:before {
	color: #d3950d;
}

#colophon .widget a:active {
	color: #d3950d;
}

#colophon .widget-title:after {
	color: #d3950d;
}


.portfolio-item .caption .title,
.portfolio-item .caption .title a {
	color: #5f727f;
}

.portfolio-item .caption .subtitle {
	color: #5f727f;
}

.portfolio-item .caption .info,
.portfolio.background-style-gray .portfolio-item:hover .wrap > .caption .subtitle,
.portfolio.background-style-dark .portfolio-item:not(:hover) .wrap > .caption .subtitle,
.news-grid.hover-horizontal-sliding .portfolio-item .image .links .caption .author .author-name,
.news-grid.hover-horizontal-sliding .portfolio-item .image .links .caption .author .author-name a {
	color: #99a9b5;
}

.portfolio-sorting label {
	color: #ffffff;
}




.portfolio-filters {
	color: #1e1e1e;
}

.portfolio-filters a {
	background-color: #fff;
}

.portfolio-filters a:hover {
	color: #ffa742;
}

.portfolio-filters a:hover {
	background-color: #ffffff;
}

.portfolio-filters a.active {
	color: #ffa742;
}

.portfolio-filters a.active {
	background-color: #ffffff;
}

.slideinfo .fancybox-title {
	background-color: #000000;
	background-color: rgba(0, 0, 0, 0.8);
}

.fancybox-title .slide-info-title {
	color: #ffffff;
}

.fancybox-title .slide-info-summary {
	color: #ffffff;
}

.nivo-directionNav .gem-prev:after,
.nivo-directionNav .gem-next:after,
.ls-thegem a.ls-nav-prev:after,
.ls-thegem a.ls-nav-next:after {
	background-color: #394050;
	font-size: 27px;
}

.vc_images_carousel .vc_carousel-control .icon-prev:before,
.vc_images_carousel .vc_carousel-control .icon-next:before,
.nivo-directionNav .gem-prev:after,
.nivo-directionNav .gem-next:after,
.ls-thegem a.ls-nav-prev:after,
.ls-thegem a.ls-nav-next:after {
	color: #ffffff;
}

.gem-prev:after,
.gem-next:after,
.row .wpb_gallery .wpb_flexslider .flex-direction-nav a,
.row .theme-default .nivo-directionNav a,
.row .vc_carousel-control .icon-next,
.row .vc_carousel-control .icon-prev,
.thegem_pf_item .post-meta-navigation a,
.thegem_pf_item .post-meta-left .post-meta-date:before {
	color: #3c3950;
}

.gem-prev:after,
.gem-next:after,
.row .theme-default .nivo-directionNav a,
.row .vc_images_carousel .vc_carousel-control .icon-prev,
.row .vc_images_carousel .vc_carousel-control .icon-next,
.portfolio.portfolio-slider .portolio-slider-prev span,
.portfolio.portfolio-slider .portolio-slider-next span {
	background-color: #1e1e1e;
}

.gem-prev:hover:after,
.gem-next:hover:after,
.row .wpb_gallery .wpb_flexslider .flex-direction-nav a:hover,
.row .theme-default .nivo-directionNav a:hover,
.row .vc_images_carousel .vc_carousel-control .icon-prev:hover,
.row .vc_images_carousel .vc_carousel-control .icon-next:hover,
.row .vc_images_carousel .vc_carousel-control .icon-prev:hover:before,
.row .vc_images_carousel .vc_carousel-control .icon-next:hover:before,
.portfolio.portfolio-slider .portolio-slider-prev span,
.portfolio.portfolio-slider .portolio-slider-next span,
.portfolio.portfolio-slider .portolio-slider-prev:hover span,
.portfolio.portfolio-slider .portolio-slider-next:hover span {
	color: #ffffff;
}

.gem-prev:hover:after,
.gem-next:hover:after,
.gem-testimonials.fullwidth-block .gem-prev:hover:after,
.gem-testimonials.fullwidth-block .gem-next:hover:after,
.row .wpb_gallery .wpb_flexslider .flex-direction-nav a:hover,
.row .theme-default .nivo-directionNav a:hover,
.row .vc_images_carousel .vc_carousel-control .icon-prev:hover,
.row .vc_images_carousel .vc_carousel-control .icon-next:hover,
.portfolio.portfolio-slider .portolio-slider-prev:hover span,
.portfolio.portfolio-slider .portolio-slider-next:hover span {
	background-color: #d3950d;
}





.gem-image a:before,
.gem-textbox-picture a:before,
.team-person-image a:before,
.team-person-image span:before,
.gem-gallery .gem-gallery-preview-carousel-wrap .gem-gallery-item a:before,
.gem-simple-gallery .gem-gallery-item a:before,
.portfolio-item .image .overlay:before,
.gallery-item .overlay:before,
.widget .flickr-item a:before,
.widget-gem-portfolio-item .widget-gem-portfolio-item-hover,
div.blog article a.youtube:before,
div.blog article a.vimeo:before,
div.blog article a.self_video:before,
div.blog article a.default:before,
.widget .gem-pp-posts a:before,
.widget .gem-latest-news-image a:before {
		background-color: rgba(211, 149, 13, 0.8);
	}
.gem-gallery .gem-gallery-thumbs-carousel-wrap .gem-gallery-item a:before,
.gem-gallery-hover-default .active .gem-gallery-item-image:after {
	background-color: #d3950d;
}
.gem-gallery .gem-gallery-thumbs-carousel-wrap .gem-gallery-item svg {
	fill: #d3950d;
}
.gem-gallery-hover-default .active .gem-gallery-item-image:after {
	background-color: #d3950d;
}
.portfolio.products.hover-zooming-blur .portfolio-item .overlay .product-rating .star-rating > span:before,
.portfolio.products.hover-zooming-blur .portfolio-item .image .links .caption .product-price .amount,
.portfolio.products.hover-zooming-blur .portfolio-item .image .links .caption .product-price,
.portfolio.products.hover-zooming-blur .portfolio-item .image .overlay .portfolio-icons.product-bottom a.added_to_cart,
.portfolio.products.hover-zooming-blur .portfolio-item .image .overlay .portfolio-icons.product-bottom .yith-wcwl-wishlistaddedbrowse a {
	color: #d3950d;
}
body .products .product-image .woo-product-overlay,
body #primary-menu.no-responsive > li.menu-item-cart > .minicart ul li .minicart-image:after,
body .hamburger-minicart > .minicart ul li .minicart-image:after,
body.mobile-cart-position-top #site-header .mobile-cart ul li .minicart-image:after,
body .widget_shopping_cart ul li .minicart-image:after,
body ul.product_list_widget .gem-products-image a:before {
		background-color: rgba(211, 149, 13, 0.3);
	}

.gem-gallery-hover-zooming-blur .gem-gallery-preview-carousel-wrap .gem-gallery-item a:before,
.gem-gallery-hover-zooming-blur .gem-gallery-preview-carousel-wrap:hover .gem-gallery-item a:before,
.portfolio.hover-zooming-blur .portfolio-item .image .overlay:before,
.portfolio.hover-new-zooming-blur .portfolio-item .image .overlay:before,
.hover-new-zooming-blur .portfolio-item .gem-simple-gallery .gem-gallery-item a:before,
.portfolio.hover-default-zooming-blur .portfolio-item .image .overlay:before,
.hover-default-zooming-blur .portfolio-item .gem-simple-gallery .gem-gallery-item a:before,
.hover-zooming-blur .portfolio-item .gem-simple-gallery .gem-gallery-item a:before,
.gem-gallery-grid.hover-zooming-blur .gallery-item .overlay:before,
.gem-gallery-hover-zooming-blur .gem-gallery-item .gem-gallery-item-image a:before,
body .blog-style-styled_list1 article a.default:before,
body .blog-style-styled_list2 article a.default:before,
body .blog-style-compact article a.default:before,
.blog article .post-featured-content > a:before,
.blog .gem-simple-gallery .gem-gallery-item a:before {
		background-color: rgba(255, 255, 255, 0.8);
	}
.gem-gallery.gem-gallery-hover-zooming-blur .gem-gallery-thumbs-carousel-wrap .gem-gallery-item svg,
.gem-gallery.gem-gallery-hover-gradient .gem-gallery-thumbs-carousel-wrap .gem-gallery-item svg{
	fill: #ffffff;
}
.gem-gallery.gem-gallery-hover-zooming-blur .active .gem-gallery-item-image:after,
.gem-gallery.gem-gallery-hover-gradient .active .gem-gallery-item-image:after
{
	background-color: #ffffff;
}
.portfolio.products.hover-zooming-blur .portfolio-item .overlay .product-rating .star-rating:before {
	color: #ffffff;
}

.gem-gallery-hover-horizontal-sliding .gem-gallery-item .gem-gallery-item-image a:before,
.portfolio.hover-horizontal-sliding .overlay:before,
.gem-gallery-grid.hover-horizontal-sliding .gallery-item .overlay:before,
.hover-new-horizontal-sliding .portfolio-item .image .overlay:before,
.hover-new-horizontal-sliding .portfolio-item .gem-simple-gallery .gem-gallery-item a:before,
.hover-default-horizontal-sliding .portfolio-item .image .overlay:before,
.hover-default-horizontal-sliding .portfolio-item .gem-simple-gallery .gem-gallery-item a:before,
.hover-horizontal-sliding .portfolio-item .gem-simple-gallery .gem-gallery-item a:before {
		background-color: rgba(70, 72, 92, 0.8);
	}
.gem-gallery-hover-horizontal-sliding .active .gem-gallery-item-image:after {
	background-color: #46485c;
}
.gem-gallery.gem-gallery-hover-horizontal-sliding .gem-gallery-thumbs-carousel-wrap .gem-gallery-item svg{
	fill: #46485c;
}
.team-person-image span:before,
.gem-team-style-5 .team-person-image a:before,
.gem-team-style-5 .team-person-image span:before {
		background-color: rgba(70, 72, 92, 0.7);
	}



.gem-gallery-hover-vertical-sliding .gem-gallery-item .gem-gallery-item-image a:before,
.portfolio.hover-vertical-sliding .overlay:before,
.portfolio.hover-new-vertical-sliding .overlay:before,
.portfolio.hover-new-vertical-sliding .portfolio-item .gem-simple-gallery .gem-gallery-item a:before,
.portfolio.hover-default-vertical-sliding .overlay:before,
.portfolio.hover-default-vertical-sliding .portfolio-item .gem-simple-gallery .gem-gallery-item a:before,
.hover-vertical-sliding .portfolio-item .gem-simple-gallery .gem-gallery-item a:before,
.gem-gallery-grid.hover-vertical-sliding .gallery-item .overlay:before {
		background-color: rgba(211, 149, 13, 0.8);
	}
.gem-gallery-hover-vertical-sliding .active .gem-gallery-item-image:after {
	background-color: #d3950d;
}
.gem-gallery.gem-gallery-hover-vertical-sliding .gem-gallery-thumbs-carousel-wrap .gem-gallery-item svg{
	fill: #d3950d;
}

.quickfinder-item-title,
.quickfinder-item-title a,
.project_info-item-style-2 .title {
	color: #4c5867;
}

.quickfinder-item-text,
.quickfinder-item-text a {
	color: #5f727f;
}

.gem-testimonials .gem-prev:after,
.gem-testimonials .gem-next:after {
	color: #ffffff;
}

.gem-testimonials .gem-prev:hover:after,
.gem-testimonials .gem-next:hover:after {
	color: #ffffff;
}

body .gem-testimonials .gem-prev:after,
body .gem-testimonials.fullwidth-block .gem-prev:after,
body .gem-testimonials .gem-next:after,
body .gem-testimonials.fullwidth-block .gem-next:after {
	background-color: #262626;
}

body .gem-testimonials .gem-prev:hover:after,
body .gem-testimonials.fullwidth-block .gem-prev:hover:after,
body .gem-testimonials .gem-next:hover:after,
body .gem-testimonials.fullwidth-block .gem-next:hover:after {
	background-color: #ffa742;
}

.widget_archive li:before,
.widget_nav_menu ul.menu li > a:before,
.widget_submenu > div > ul li > a:before,
.widget_categories > ul li > a:before,
.widget_product_categories > ul li > a:before,
.widget_pages > ul li > a:before,
.widget_meta li:before,
.widget_rss li:before,
.project_info-item-style-2 .icon,
.gem_accordion_header .ui-accordion-header-icon:before,
.wpb_toggle:after,
.vc_toggle_title:after,
.widget_layered_nav ul li a:before,
.widget .gem-contacts-address:before,
.widget .gem-contacts-phone:before,
.widget .gem-contacts-fax:before,
.widget .gem-contacts-email:before,
.widget .gem-contacts-website:before,
.resp-accordion:after {
	color: #535353;
}

.gem-icon,
.widget .team-person-email a:before,
.woocommerce-message:before,
.woocommerce-info:before {
	color: #535353;
}


.gem-pagination a,
.gem-pagination .current {
	border: 2px solid #535353;
}
.gem-pagination .prev,
.gem-pagination .next {
	background-color: #535353;
}
.gem-pagination a,
.gem-pagination .current {
	color: #535353;
}
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-page a {
	border: 2px solid #535353 !important;
}
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-prev a,
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-next a {
	background-color: #535353 !important;
}
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-page a {
	color: #535353 !important;
}

.gem-pagination a,
.gem-pagination .current {
	background-color: #ffffff;
}
.gem-pagination a:hover,
.gem-pagination .current,
.gem-pagination .prev,
.gem-pagination .next {
	color: #ffffff;
}
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-page a {
	background-color: #ffffff !important;
}
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-page a:hover,
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-prev a:hover,
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-next a:hover,
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-prev a,
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-next a,
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-page.vc_grid-active a {
	color: #ffffff !important;
}

.gem-pagination a:hover {
	background-color: #d3950d;
}
.gem-pagination a:hover {
	border-color: #d3950d;
}
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-page a:hover,
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-prev a:hover,
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-next a:hover {
	background-color: #d3950d;
}

.gem-pagination .current {
	background-color: #3c3950;
}
.gem-pagination .current {
	border-color: #3c3950;
}
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-page.vc_grid-active a {
	background-color: #3c3950 !important;
}
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-page.vc_grid-active a{
	background-color: #3c3950 !important;
}

.gem-mini-pagination a,
.flexslider .flex-control-paging li a,
.flexslider .flex-control-paging li a:hover,
.vc_carousel-indicators li,
.row .gem-mini-pagination a,
.row .flexslider .flex-control-paging li a,
.row .flexslider .flex-control-paging li a:hover,
.row .vc_carousel-indicators li,
.vc_pagination.vc_pagination-style-thegem.vc_pagination-shape-circle .vc_pagination-trigger,
.vc_pagination.vc_pagination-style-thegem.vc_pagination-shape-circle .vc_pagination-trigger:hover,
.row .theme-default .nivo-controlNav a {
	background-color: #535353;
}

.gem-mini-pagination a.selected,
.gem-mini-pagination a.active,
.flexslider .flex-control-paging li a.flex-active,
.flexslider .flex-control-paging li a.flex-active:hover,
.vc_carousel-indicators .vc_active,
.row .flexslider .flex-control-paging li a.flex-active,
.row .flexslider .flex-control-paging li a.flex-active:hover,
.row .vc_carousel-indicators .vc_active,
.vc_pagination.vc_pagination-style-thegem.vc_pagination-shape-circle .vc_active .vc_pagination-trigger,
.row .theme-default .nivo-controlNav a.active {
	background-color: #d3950d;
}

body .gem-testimonials.style1 .gem-testimonial-wrapper:after,
body .gem-testimonials.style2 .gem-testimonial-wrapper p:after {
	color: #d3950d;
}

blockquote:after {
	color: #d3950d;
}


#footer-socials a.socials-item {
	color: #ffffff;
}


/*
input[type="text"],
input[type="password"],
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="number"],
input[type="range"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="month"],
input[type="week"],
textarea,
.chosen-container,
.select2-container,
.checkbox-sign,
.radio-sign {
	background-color: #f2f2f2;
}
*/
.chosen-container-single .chosen-single div:before,
.select2-container .select2-choice .select2-arrow:before {
	color: #f2f2f2;
}

input[type="text"],
input[type="password"],
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="number"],
input[type="range"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="month"],
input[type="week"],
textarea,
select,
.chosen-container-single .chosen-single,
.select2-container .select2-choice,
.widget_product_search form:before {
	color: #979797;
}

input[type="text"],
input[type="password"],
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="number"],
input[type="range"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="month"],
input[type="week"],
textarea,
.combobox-wrapper,
#page .woocommerce .select2-container .select2-selection--single,
.select2-container .select2-dropdown,
.chosen-container-single .chosen-single,
.select2-container .select2-choice,
select.wpcf7-form-control.wpcf7-select {
	border: 1px solid #f2f2f2;
}
.chosen-container-single .chosen-single div,
.edit-address-form .form-row .chosen-container-single .chosen-single div,
.select2-container .select2-choice .select2-arrow {
	background-color: #f2f2f2;
}

body .breadcrumbs a,
body .bc-devider:before {
	color: #fff;
}

body .breadcrumbs .current {
	color: #ffa742;
	border-bottom: 3px solid #ffa742;
}

body .breadcrumbs a:hover {
	color: #ffa742;
}

body .breadcrumbs a:hover {
	color: #ffa742;
}

#page-preloader {
	background-color: #323031;
}

#page-preloader .page-preloader-spin,
.block-preloader .page-preloader-spin,
.preloader-spin {
	border-top-color: #ffa742;
}

#page-preloader .page-preloader-spin:before,
.block-preloader .page-preloader-spin:before,
.preloader-spin:before {
	border-top-color: #ffa742;
}

#page-preloader .page-preloader-spin:after,
.block-preloader .page-preloader-spin:after,
.preloader-spin:after,
.gem-fancybox-preloader .preloader-spin:after {
	border-top-color: #ffa742;
}

.products .product .product-inner:not(:hover) .product-info .product-title,
.products .portfolio-item:not(:hover) .wrap > .caption .title {
	color: #5f727f;
}

.single-product-content .product_title {
	color: #3c3950;
}

.widget .gem-products-content .gem-products-title,
.widget .gem-products-content .gem-products-title a {
	color: #5f727f;
}

.shop_table .product-name .product-title,
.shop_table .product-name .product-title a {
	color: #2496bc;
}

.products .product-info .price ins,
.products .product-info .price .amount {
	color: #2496bc;
}

.single-product-content .price {
	color: #3c3950;
}

.widget .gem-products-content .gem-products-price ins,
.widget .gem-products-content .gem-products-price .amount {
	color: #3c3950;
}

.woocommerce .shop_table tr td.product-price,
.woocommerce .shop_table tr td.product-subtotal,
.woocommerce .wishlist_table .product-info .product-info-content .product-price,
body .woocommerce .shop_table td.product-price,
body .woocommerce .shop_table td.product-subtotal,
body .woocommerce .wishlist_table .product-info .product-info-content .product-price,
body .woocommerce .woocommerce-cart-form.responsive .cart-item .gem-table .shop_table td.product-price,
body .woocommerce .woocommerce-cart-form.responsive .cart-item .gem-table .shop_table td.product-subtotal,
body .woocommerce .cart_totals table td .amount,
body .woocommerce .cart_totals table tr.order-total td .amount,
body .woocommerce .cart_totals table tr td,
body .woocommerce .woocommerce-checkout .shop_table.woocommerce-checkout-payment-total .cart-subtotal td .amount,
body .woocommerce .woocommerce-checkout #order_review .woocommerce-checkout-payment .payment_methods .payment_box,
body .woocommerce .woocommerce-checkout #order_review .woocommerce-checkout-payment .payment_methods .payment_box:before,
body .woocommerce .woocommerce-checkout.woocommerce-checkout-one-page .checkout-payment .shop_table td.product-total .amount,
body .woocommerce .woocommerce-checkout.woocommerce-checkout-one-page .shop_table.woocommerce-checkout-payment-total .order-total td .amount,
body #primary-menu.no-responsive > li.menu-item-cart > .minicart ul li .quantity span.amount,
body #primary-menu.no-responsive > li.menu-item-cart > .minicart .total span.amount,
body .hamburger-minicart > .minicart ul li .quantity span.amount,
body .hamburger-minicart > .minicart .total span.amount,
body.mobile-cart-position-top #site-header .mobile-cart .total span.amount {
	color: #3c3950;
}


.portfolio.item-separator .portfolio-item:before,
.portfolio.item-separator .portfolio-item:after,
.portfolio.item-separator .portfolio-item .item-separator-box:before,
.portfolio.item-separator .portfolio-item .item-separator-box:after {
	border-color: #000000;
}








.wpcf7-form.gem-contact-form-white input[type="text"],
.wpcf7-form.gem-contact-form-white input[type="password"],
.wpcf7-form.gem-contact-form-white input[type="color"],
.wpcf7-form.gem-contact-form-white input[type="date"],
.wpcf7-form.gem-contact-form-white input[type="datetime"],
.wpcf7-form.gem-contact-form-white input[type="datetime-local"],
.wpcf7-form.gem-contact-form-white input[type="email"],
.wpcf7-form.gem-contact-form-white input[type="number"],
.wpcf7-form.gem-contact-form-white input[type="range"],
.wpcf7-form.gem-contact-form-white input[type="search"],
.wpcf7-form.gem-contact-form-white input[type="tel"],
.wpcf7-form.gem-contact-form-white input[type="time"],
.wpcf7-form.gem-contact-form-white input[type="url"],
.wpcf7-form.gem-contact-form-white input[type="month"],
.wpcf7-form.gem-contact-form-white input[type="week"],
.wpcf7-form.gem-contact-form-white textarea,
.wpcf7-form.gem-contact-form-white .combobox-wrapper .combobox-text,
.wpcf7-form.gem-contact-form-white select,
#colophon .wpcf7-form.gem-contact-form-white input[type="text"],
#colophon .wpcf7-form.gem-contact-form-white input[type="password"],
#colophon .wpcf7-form.gem-contact-form-white input[type="color"],
#colophon .wpcf7-form.gem-contact-form-white input[type="date"],
#colophon .wpcf7-form.gem-contact-form-white input[type="datetime"],
#colophon .wpcf7-form.gem-contact-form-white input[type="datetime-local"],
#colophon .wpcf7-form.gem-contact-form-white input[type="email"],
#colophon .wpcf7-form.gem-contact-form-white input[type="number"],
#colophon .wpcf7-form.gem-contact-form-white input[type="range"],
#colophon .wpcf7-form.gem-contact-form-white input[type="search"],
#colophon .wpcf7-form.gem-contact-form-white input[type="tel"],
#colophon .wpcf7-form.gem-contact-form-white input[type="time"],
#colophon .wpcf7-form.gem-contact-form-white input[type="url"],
#colophon .wpcf7-form.gem-contact-form-white input[type="month"],
#colophon .wpcf7-form.gem-contact-form-white input[type="week"],
#colophon .wpcf7-form.gem-contact-form-white textarea,
#colophon .wpcf7-form.gem-contact-form-white .combobox-wrapper .combobox-text,
#colophon .wpcf7-form.gem-contact-form-white select {
	color: #262626;
}

.wpcf7-form.gem-contact-form-white input[type="text"],
.wpcf7-form.gem-contact-form-white input[type="password"],
.wpcf7-form.gem-contact-form-white input[type="color"],
.wpcf7-form.gem-contact-form-white input[type="date"],
.wpcf7-form.gem-contact-form-white input[type="datetime"],
.wpcf7-form.gem-contact-form-white input[type="datetime-local"],
.wpcf7-form.gem-contact-form-white input[type="email"],
.wpcf7-form.gem-contact-form-white input[type="number"],
.wpcf7-form.gem-contact-form-white input[type="range"],
.wpcf7-form.gem-contact-form-white input[type="search"],
.wpcf7-form.gem-contact-form-white input[type="tel"],
.wpcf7-form.gem-contact-form-white input[type="time"],
.wpcf7-form.gem-contact-form-white input[type="url"],
.wpcf7-form.gem-contact-form-white input[type="month"],
.wpcf7-form.gem-contact-form-white input[type="week"],
.wpcf7-form.gem-contact-form-white textarea,
.wpcf7-form.gem-contact-form-white .combobox-wrapper,
.wpcf7-form.gem-contact-form-white .combobox-wrapper .combobox-button,
.wpcf7-form.gem-contact-form-white .combobox-wrapper .combobox-text,
.wpcf7-form.gem-contact-form-white select,
.wpcf7-form.gem-contact-form-white .checkbox-sign,
#colophon .wpcf7-form.gem-contact-form-white input[type="text"],
#colophon .wpcf7-form.gem-contact-form-white input[type="password"],
#colophon .wpcf7-form.gem-contact-form-white input[type="color"],
#colophon .wpcf7-form.gem-contact-form-white input[type="date"],
#colophon .wpcf7-form.gem-contact-form-white input[type="datetime"],
#colophon .wpcf7-form.gem-contact-form-white input[type="datetime-local"],
#colophon .wpcf7-form.gem-contact-form-white input[type="email"],
#colophon .wpcf7-form.gem-contact-form-white input[type="number"],
#colophon .wpcf7-form.gem-contact-form-white input[type="range"],
#colophon .wpcf7-form.gem-contact-form-white input[type="search"],
#colophon .wpcf7-form.gem-contact-form-white input[type="tel"],
#colophon .wpcf7-form.gem-contact-form-white input[type="time"],
#colophon .wpcf7-form.gem-contact-form-white input[type="url"],
#colophon .wpcf7-form.gem-contact-form-white input[type="month"],
#colophon .wpcf7-form.gem-contact-form-white input[type="week"],
#colophon .wpcf7-form.gem-contact-form-white textarea,
#colophon .wpcf7-form.gem-contact-form-white .combobox-wrapper,
#colophon .wpcf7-form.gem-contact-form-white .combobox-wrapper .combobox-button,
#colophon .wpcf7-form.gem-contact-form-white .combobox-wrapper .combobox-text,
#colophon .wpcf7-form.gem-contact-form-white select,
#colophon .wpcf7-form.gem-contact-form-white .checkbox-sign {
	background-color: #f7f7f7;
}

.wpcf7-form.gem-contact-form-white input[type="text"],
.wpcf7-form.gem-contact-form-white input[type="password"],
.wpcf7-form.gem-contact-form-white input[type="color"],
.wpcf7-form.gem-contact-form-white input[type="date"],
.wpcf7-form.gem-contact-form-white input[type="datetime"],
.wpcf7-form.gem-contact-form-white input[type="datetime-local"],
.wpcf7-form.gem-contact-form-white input[type="email"],
.wpcf7-form.gem-contact-form-white input[type="number"],
.wpcf7-form.gem-contact-form-white input[type="range"],
.wpcf7-form.gem-contact-form-white input[type="search"],
.wpcf7-form.gem-contact-form-white input[type="tel"],
.wpcf7-form.gem-contact-form-white input[type="time"],
.wpcf7-form.gem-contact-form-white input[type="url"],
.wpcf7-form.gem-contact-form-white input[type="month"],
.wpcf7-form.gem-contact-form-white input[type="week"],
.wpcf7-form.gem-contact-form-white textarea,
.wpcf7-form.gem-contact-form-white .combobox-wrapper,
.wpcf7-form.gem-contact-form-white select,
.wpcf7-form.gem-contact-form-white .checkbox-sign,
#colophon .wpcf7-form.gem-contact-form-white input[type="text"],
#colophon .wpcf7-form.gem-contact-form-white input[type="password"],
#colophon .wpcf7-form.gem-contact-form-white input[type="color"],
#colophon .wpcf7-form.gem-contact-form-white input[type="date"],
#colophon .wpcf7-form.gem-contact-form-white input[type="datetime"],
#colophon .wpcf7-form.gem-contact-form-white input[type="datetime-local"],
#colophon .wpcf7-form.gem-contact-form-white input[type="email"],
#colophon .wpcf7-form.gem-contact-form-white input[type="number"],
#colophon .wpcf7-form.gem-contact-form-white input[type="range"],
#colophon .wpcf7-form.gem-contact-form-white input[type="search"],
#colophon .wpcf7-form.gem-contact-form-white input[type="tel"],
#colophon .wpcf7-form.gem-contact-form-white input[type="time"],
#colophon .wpcf7-form.gem-contact-form-white input[type="url"],
#colophon .wpcf7-form.gem-contact-form-white input[type="month"],
#colophon .wpcf7-form.gem-contact-form-white input[type="week"],
#colophon .wpcf7-form.gem-contact-form-white textarea,
#colophon .wpcf7-form.gem-contact-form-white .combobox-wrapper,
#colophon .wpcf7-form.gem-contact-form-white select,
#colophon .wpcf7-form.gem-contact-form-white .checkbox-sign {
	border: 1px solid #dfe5e8;
}

.wpcf7-form.gem-contact-form-white input[type="text"]::placeholder,
.wpcf7-form.gem-contact-form-white input[type="password"]::placeholder,
.wpcf7-form.gem-contact-form-white input[type="color"]::placeholder,
.wpcf7-form.gem-contact-form-white input[type="date"]::placeholder,
.wpcf7-form.gem-contact-form-white input[type="datetime"]::placeholder,
.wpcf7-form.gem-contact-form-white input[type="datetime-local"]::placeholder,
.wpcf7-form.gem-contact-form-white input[type="email"]::placeholder,
.wpcf7-form.gem-contact-form-white input[type="number"]::placeholder,
.wpcf7-form.gem-contact-form-white input[type="range"]::placeholder,
.wpcf7-form.gem-contact-form-white input[type="search"]::placeholder,
.wpcf7-form.gem-contact-form-white input[type="tel"]::placeholder,
.wpcf7-form.gem-contact-form-white input[type="time"]::placeholder,
.wpcf7-form.gem-contact-form-white input[type="url"]::placeholder,
.wpcf7-form.gem-contact-form-white input[type="month"]::placeholder,
.wpcf7-form.gem-contact-form-white input[type="week"]::placeholder,
.wpcf7-form.gem-contact-form-white textarea::placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="text"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="password"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="color"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="date"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="datetime"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="datetime-local"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="email"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="number"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="range"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="search"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="tel"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="time"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="url"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="month"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="week"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-white textarea::placeholder {
	color: #505050;
}
.wpcf7-form.gem-contact-form-white input[type="text"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-white input[type="password"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-white input[type="color"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-white input[type="date"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-white input[type="datetime"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-white input[type="datetime-local"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-white input[type="email"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-white input[type="number"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-white input[type="range"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-white input[type="search"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-white input[type="tel"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-white input[type="time"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-white input[type="url"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-white input[type="month"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-white input[type="week"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-white textarea::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="text"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="password"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="color"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="date"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="datetime"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="datetime-local"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="email"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="number"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="range"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="search"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="tel"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="time"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="url"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="month"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-white input[type="week"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-white textarea::-webkit-input-placeholder {
	color: #505050;
}

.wpcf7-form.gem-contact-form-white .wpcf7-form-control-wrap:after ,
.wpcf7-form.gem-contact-form-white .combobox-wrapper .combobox-button:before,
.wpcf7-form.gem-contact-form-white .checkbox-sign:before,
.wpcf7-form.gem-contact-form-white .gem-form-icon-phone:after,
.wpcf7-form.gem-contact-form-white .gem-form-icon-website:after,
.wpcf7-form.gem-contact-form-white .gem-form-icon-email:after,
.wpcf7-form.gem-contact-form-white .gem-form-icon-name:after,
#colophon .wpcf7-form.gem-contact-form-white .wpcf7-form-control-wrap:after ,
#colophon .wpcf7-form.gem-contact-form-white .combobox-wrapper .combobox-button:before,
#colophon .wpcf7-form.gem-contact-form-white .checkbox-sign:before,
#colophon .wpcf7-form.gem-contact-form-white .gem-form-icon-phone:after,
#colophon .wpcf7-form.gem-contact-form-white .gem-form-icon-website:after,
#colophon .wpcf7-form.gem-contact-form-white .gem-form-icon-email:after,
#colophon .wpcf7-form.gem-contact-form-white .gem-form-icon-name:after {
	color: #acacac;
}


.wpcf7-form.gem-contact-form-dark input[type="text"],
.wpcf7-form.gem-contact-form-dark input[type="password"],
.wpcf7-form.gem-contact-form-dark input[type="color"],
.wpcf7-form.gem-contact-form-dark input[type="date"],
.wpcf7-form.gem-contact-form-dark input[type="datetime"],
.wpcf7-form.gem-contact-form-dark input[type="datetime-local"],
.wpcf7-form.gem-contact-form-dark input[type="email"],
.wpcf7-form.gem-contact-form-dark input[type="number"],
.wpcf7-form.gem-contact-form-dark input[type="range"],
.wpcf7-form.gem-contact-form-dark input[type="search"],
.wpcf7-form.gem-contact-form-dark input[type="tel"],
.wpcf7-form.gem-contact-form-dark input[type="time"],
.wpcf7-form.gem-contact-form-dark input[type="url"],
.wpcf7-form.gem-contact-form-dark input[type="month"],
.wpcf7-form.gem-contact-form-dark input[type="week"],
.wpcf7-form.gem-contact-form-dark textarea,
.wpcf7-form.gem-contact-form-dark .combobox-wrapper .combobox-text,
.wpcf7-form.gem-contact-form-dark select,
#colophon .wpcf7-form.gem-contact-form-dark input[type="text"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="password"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="color"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="date"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="datetime"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="datetime-local"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="email"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="number"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="range"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="search"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="tel"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="time"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="url"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="month"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="week"],
#colophon .wpcf7-form.gem-contact-form-dark textarea,
#colophon .wpcf7-form.gem-contact-form-dark .combobox-wrapper .combobox-text,
#colophon .wpcf7-form.gem-contact-form-dark select {
	color: #fff;
}

.wpcf7-form.gem-contact-form-dark input[type="text"],
.wpcf7-form.gem-contact-form-dark input[type="password"],
.wpcf7-form.gem-contact-form-dark input[type="color"],
.wpcf7-form.gem-contact-form-dark input[type="date"],
.wpcf7-form.gem-contact-form-dark input[type="datetime"],
.wpcf7-form.gem-contact-form-dark input[type="datetime-local"],
.wpcf7-form.gem-contact-form-dark input[type="email"],
.wpcf7-form.gem-contact-form-dark input[type="number"],
.wpcf7-form.gem-contact-form-dark input[type="range"],
.wpcf7-form.gem-contact-form-dark input[type="search"],
.wpcf7-form.gem-contact-form-dark input[type="tel"],
.wpcf7-form.gem-contact-form-dark input[type="time"],
.wpcf7-form.gem-contact-form-dark input[type="url"],
.wpcf7-form.gem-contact-form-dark input[type="month"],
.wpcf7-form.gem-contact-form-dark input[type="week"],
.wpcf7-form.gem-contact-form-dark textarea,
.wpcf7-form.gem-contact-form-dark .combobox-wrapper,
.wpcf7-form.gem-contact-form-dark .combobox-wrapper .combobox-button,
.wpcf7-form.gem-contact-form-dark .combobox-wrapper .combobox-text,
.wpcf7-form.gem-contact-form-dark select,
.wpcf7-form.gem-contact-form-dark .checkbox-sign,
#colophon .wpcf7-form.gem-contact-form-dark input[type="text"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="password"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="color"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="date"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="datetime"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="datetime-local"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="email"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="number"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="range"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="search"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="tel"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="time"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="url"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="month"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="week"],
#colophon .wpcf7-form.gem-contact-form-dark textarea,
#colophon .wpcf7-form.gem-contact-form-dark .combobox-wrapper,
#colophon .wpcf7-form.gem-contact-form-dark .combobox-wrapper .combobox-button,
#colophon .wpcf7-form.gem-contact-form-dark .combobox-wrapper .combobox-text,
#colophon .wpcf7-form.gem-contact-form-dark select,
#colophon .wpcf7-form.gem-contact-form-dark .checkbox-sign {
	background-color: rgba(0, 0, 0, 0.33);
}

.wpcf7-form.gem-contact-form-dark input[type="text"],
.wpcf7-form.gem-contact-form-dark input[type="password"],
.wpcf7-form.gem-contact-form-dark input[type="color"],
.wpcf7-form.gem-contact-form-dark input[type="date"],
.wpcf7-form.gem-contact-form-dark input[type="datetime"],
.wpcf7-form.gem-contact-form-dark input[type="datetime-local"],
.wpcf7-form.gem-contact-form-dark input[type="email"],
.wpcf7-form.gem-contact-form-dark input[type="number"],
.wpcf7-form.gem-contact-form-dark input[type="range"],
.wpcf7-form.gem-contact-form-dark input[type="search"],
.wpcf7-form.gem-contact-form-dark input[type="tel"],
.wpcf7-form.gem-contact-form-dark input[type="time"],
.wpcf7-form.gem-contact-form-dark input[type="url"],
.wpcf7-form.gem-contact-form-dark input[type="month"],
.wpcf7-form.gem-contact-form-dark input[type="week"],
.wpcf7-form.gem-contact-form-dark textarea,
.wpcf7-form.gem-contact-form-dark .combobox-wrapper,
.wpcf7-form.gem-contact-form-dark select,
.wpcf7-form.gem-contact-form-dark .checkbox-sign,
#colophon .wpcf7-form.gem-contact-form-dark input[type="text"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="password"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="color"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="date"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="datetime"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="datetime-local"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="email"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="number"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="range"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="search"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="tel"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="time"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="url"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="month"],
#colophon .wpcf7-form.gem-contact-form-dark input[type="week"],
#colophon .wpcf7-form.gem-contact-form-dark textarea,
#colophon .wpcf7-form.gem-contact-form-dark .combobox-wrapper,
#colophon .wpcf7-form.gem-contact-form-dark select,
#colophon .wpcf7-form.gem-contact-form-dark .checkbox-sign {
	border: 1px solid #505050;
}

.wpcf7-form.gem-contact-form-dark input[type="text"]::placeholder,
.wpcf7-form.gem-contact-form-dark input[type="password"]::placeholder,
.wpcf7-form.gem-contact-form-dark input[type="color"]::placeholder,
.wpcf7-form.gem-contact-form-dark input[type="date"]::placeholder,
.wpcf7-form.gem-contact-form-dark input[type="datetime"]::placeholder,
.wpcf7-form.gem-contact-form-dark input[type="datetime-local"]::placeholder,
.wpcf7-form.gem-contact-form-dark input[type="email"]::placeholder,
.wpcf7-form.gem-contact-form-dark input[type="number"]::placeholder,
.wpcf7-form.gem-contact-form-dark input[type="range"]::placeholder,
.wpcf7-form.gem-contact-form-dark input[type="search"]::placeholder,
.wpcf7-form.gem-contact-form-dark input[type="tel"]::placeholder,
.wpcf7-form.gem-contact-form-dark input[type="time"]::placeholder,
.wpcf7-form.gem-contact-form-dark input[type="url"]::placeholder,
.wpcf7-form.gem-contact-form-dark input[type="month"]::placeholder,
.wpcf7-form.gem-contact-form-dark input[type="week"]::placeholder,
.wpcf7-form.gem-contact-form-dark textarea::placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="text"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="password"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="color"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="date"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="datetime"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="datetime-local"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="email"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="number"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="range"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="search"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="tel"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="time"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="url"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="month"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="week"]::placeholder,
#colophon .wpcf7-form.gem-contact-form-dark textarea::placeholder {
	color: #979797;
}
.wpcf7-form.gem-contact-form-dark input[type="text"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-dark input[type="password"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-dark input[type="color"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-dark input[type="date"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-dark input[type="datetime"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-dark input[type="datetime-local"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-dark input[type="email"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-dark input[type="number"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-dark input[type="range"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-dark input[type="search"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-dark input[type="tel"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-dark input[type="time"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-dark input[type="url"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-dark input[type="month"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-dark input[type="week"]::-webkit-input-placeholder,
.wpcf7-form.gem-contact-form-dark textarea::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="text"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="password"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="color"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="date"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="datetime"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="datetime-local"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="email"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="number"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="range"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="search"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="tel"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="time"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="url"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="month"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-dark input[type="week"]::-webkit-input-placeholder,
#colophon .wpcf7-form.gem-contact-form-dark textarea::-webkit-input-placeholder {
	color: #979797;
}

.wpcf7-form.gem-contact-form-dark .wpcf7-form-control-wrap:after ,
.wpcf7-form.gem-contact-form-dark .combobox-wrapper .combobox-button:before,
.wpcf7-form.gem-contact-form-dark .checkbox-sign:before,
.wpcf7-form.gem-contact-form-dark .gem-form-icon-phone:after,
.wpcf7-form.gem-contact-form-dark .gem-form-icon-website:after,
.wpcf7-form.gem-contact-form-dark .gem-form-icon-email:after,
.wpcf7-form.gem-contact-form-dark .gem-form-icon-name:after,
#colophon .wpcf7-form.gem-contact-form-dark .wpcf7-form-control-wrap:after ,
#colophon .wpcf7-form.gem-contact-form-dark .combobox-wrapper .combobox-button:before,
#colophon .wpcf7-form.gem-contact-form-dark .checkbox-sign:before,
#colophon .wpcf7-form.gem-contact-form-dark .gem-form-icon-phone:after,
#colophon .wpcf7-form.gem-contact-form-dark .gem-form-icon-website:after,
#colophon .wpcf7-form.gem-contact-form-dark .gem-form-icon-email:after,
#colophon .wpcf7-form.gem-contact-form-dark .gem-form-icon-name:after {
	color: #979797;
}


.yikes-mailchimp-container form.yikes-easy-mc-form input[type="text"],
.yikes-mailchimp-container form.yikes-easy-mc-form input[type="email"],
.yikes-mailchimp-container form.yikes-easy-mc-form input[type="number"],
.yikes-mailchimp-container form.yikes-easy-mc-form input[type="url"],
.yikes-mailchimp-container form.yikes-easy-mc-form textarea,
.yikes-mailchimp-container form.yikes-easy-mc-form .combobox-wrapper .combobox-text,
.yikes-mailchimp-container form.yikes-easy-mc-form select,
form.mc4wp-form input[type="text"],
form.mc4wp-form input[type="email"],
form.mc4wp-form input[type="tel"],
form.mc4wp-form input[type="number"],
form.mc4wp-form input[type="url"],
form.mc4wp-form input[type="date"],
form.mc4wp-form textarea,
form.mc4wp-form .combobox-wrapper .combobox-text,
form.mc4wp-form select {
	color: #000000;
}

.yikes-mailchimp-container form.yikes-easy-mc-form input[type="text"],
.yikes-mailchimp-container form.yikes-easy-mc-form input[type="email"],
.yikes-mailchimp-container form.yikes-easy-mc-form input[type="number"],
.yikes-mailchimp-container form.yikes-easy-mc-form input[type="url"],
.yikes-mailchimp-container form.yikes-easy-mc-form textarea,
.yikes-mailchimp-container form.yikes-easy-mc-form .combobox-wrapper,
.yikes-mailchimp-container form.yikes-easy-mc-form .combobox-wrapper .combobox-button,
.yikes-mailchimp-container form.yikes-easy-mc-form .combobox-wrapper .combobox-text,
.yikes-mailchimp-container form.yikes-easy-mc-form select,
.yikes-mailchimp-container form.yikes-easy-mc-form .checkbox-sign,
form.mc4wp-form input[type="text"],
form.mc4wp-form input[type="email"],
form.mc4wp-form input[type="tel"],
form.mc4wp-form input[type="number"],
form.mc4wp-form input[type="url"],
form.mc4wp-form input[type="date"],
form.mc4wp-form textarea,
form.mc4wp-form .combobox-wrapper,
form.mc4wp-form .combobox-wrapper .combobox-button,
form.mc4wp-form .combobox-wrapper .combobox-text,
form.mc4wp-form select,
form.mc4wp-form .checkbox-sign {
	background-color: #ffffff;
}

.yikes-mailchimp-container form.yikes-easy-mc-form input[type="text"],
.yikes-mailchimp-container form.yikes-easy-mc-form input[type="email"],
.yikes-mailchimp-container form.yikes-easy-mc-form input[type="number"],
.yikes-mailchimp-container form.yikes-easy-mc-form input[type="url"],
.yikes-mailchimp-container form.yikes-easy-mc-form textarea,
.yikes-mailchimp-container form.yikes-easy-mc-form .combobox-wrapper,
.yikes-mailchimp-container form.yikes-easy-mc-form select,
.yikes-mailchimp-container form.yikes-easy-mc-form .checkbox-sign,
form.mc4wp-form input[type="text"],
form.mc4wp-form input[type="email"],
form.mc4wp-form input[type="tel"],
form.mc4wp-form input[type="number"],
form.mc4wp-form input[type="url"],
form.mc4wp-form input[type="date"],
form.mc4wp-form textarea,
form.mc4wp-form .combobox-wrapper,
form.mc4wp-form select,
form.mc4wp-form .checkbox-sign {
	border: 1px solid #ffffff;
}

.yikes-mailchimp-container form.yikes-easy-mc-form input[type="text"]::placeholder,
.yikes-mailchimp-container form.yikes-easy-mc-form input[type="email"]::placeholder,
.yikes-mailchimp-container form.yikes-easy-mc-form input[type="number"]::placeholder,
.yikes-mailchimp-container form.yikes-easy-mc-form input[type="url"]::placeholder,
.yikes-mailchimp-container form.yikes-easy-mc-form textarea::placeholder,
form.mc4wp-form input[type="text"]::placeholder,
form.mc4wp-form input[type="email"]::placeholder,
form.mc4wp-form input[type="tel"]::placeholder,
form.mc4wp-form input[type="number"]::placeholder,
form.mc4wp-form input[type="url"]::placeholder,
form.mc4wp-form input[type="date"]::placeholder,
form.mc4wp-form textarea::placeholder {
	color: #777777;
}
.yikes-mailchimp-container form.yikes-easy-mc-form input[type="text"]::-webkit-input-placeholder,
.yikes-mailchimp-container form.yikes-easy-mc-form input[type="email"]::-webkit-input-placeholder,
.yikes-mailchimp-container form.yikes-easy-mc-form input[type="number"]::-webkit-input-placeholder,
.yikes-mailchimp-container form.yikes-easy-mc-form input[type="url"]::-webkit-input-placeholder,
.yikes-mailchimp-container form.yikes-easy-mc-form textarea::-webkit-input-placeholder,
form.mc4wp-form input[type="text"]::-webkit-input-placeholder,
form.mc4wp-form input[type="email"]::-webkit-input-placeholder,
form.mc4wp-form input[type="tel"]::-webkit-input-placeholder,
form.mc4wp-form input[type="number"]::-webkit-input-placeholder,
form.mc4wp-form input[type="url"]::-webkit-input-placeholder,
form.mc4wp-form input[type="date"]::-webkit-input-placeholder,
form.mc4wp-form textarea::-webkit-input-placeholder {
	color: #777777;
}

.yikes-mailchimp-container form.yikes-easy-mc-form,
form.mc4wp-form {
	color: #ffffff;
}

.yikes-mailchimp-container form.yikes-easy-mc-form label,
form.mc4wp-form label {
	color: #ffffff;
}

.yikes-easy-mc-form .yikes-easy-mc-submit-button.gem-button,
form.mc4wp-form input[type="submit"] {
	color: #ffffff;
}

.yikes-easy-mc-form .yikes-easy-mc-submit-button.gem-button:hover,
form.mc4wp-form input[type="submit"]:hover {
	color: #ffffff;
}

.yikes-easy-mc-form .yikes-easy-mc-submit-button.gem-button,
form.mc4wp-form input[type="submit"] {
	background-color: #ffa742;
}

.yikes-easy-mc-form .yikes-easy-mc-submit-button.gem-button:hover,
form.mc4wp-form input[type="submit"]:hover {
	background-color: #1e1e1e;
}












.preloader:after,
.loading:after {
	display: none;
}
.preloader .preloader-spin,
.loading .preloader-spin {
	display: block;
}

.top-area-contacts .gem-contacts-address:before {
	color: #ffffff;
}


.top-area-contacts .gem-contacts-phone:before {
	color: #ffffff;
}


.top-area-contacts .gem-contacts-fax:before {
	color: #ffffff;
}

.top-area-contacts .gem-contacts-fax:before {
	font-family: 'FontAwesome';
	content: '\F232';
}

.top-area-contacts .gem-contacts-email:before {
	color: #ffffff;
}


.top-area-contacts .gem-contacts-website:before {
	color: #ffffff;
}



         .custom-title-title  .title-xlarge{
		text-transform: none;
                font-size: 150px;
                line-height:  normal;
                padding-left: 120px;
	}

       .title-xlarge {
                 text-transform: none;
        }

         .vc_custom_heading.custom-title-bold{
                 letter-spacing: 5px;
          }

        .post-related-posts h2:before,
        .comments-title:before, 
        .comment-reply-title:before{
		color: #f6aa0f;
	}

	body.search .page-title-block .highlight{
		color: #ffffff;
	}
        body.search .page-title-block:before {
               background-color:  rgba(255,255,255,0.3);
       }

	.contact-form .form-line{
		margin-bottom: 30px;
	}

        .gear-1,
	.gear-2,
	.gear-3,
	.gear-4,
	.gear-5,
	.gear-6,
	.gear-7{
		position: absolute;
		margin: 0;
	}
	.gear-1 {
		z-index: 1;
	}
	.gear-2 {
		z-index: 2;
	}
	.gear-3 {
		z-index: 3;
	}
	.gear-4 {
		z-index: 4;
	}
	.gear-5 {
		z-index: 5;
	}
	.gear-6 {
		z-index: 6;
	}
	.gear-7 {
		z-index: 7;
	}
	.gear-1 {
		left: 66%;
		top: 110%;
	}
	.gear-2 {
		left: 33%;
		top: 100%;
	}
	.gear-3 {
		left: 46%;
		top: 80%;
	}
	.gear-4 {
		left: 10%;
		top: 25%;
	}
	.gear-5 {
		left: 78%;
		top: 110%;
	}
	.gear-6 {
		left: 57%;
		top: 95%;
	}
	.gear-7 {
		left: 85%;
		top: 120%;
	}
	.custom-overflow .fullwidth-block {
		overflow: hidden;
	}


        .book-form .form-line{
		margin-top: 20px;
	}

        .restaurant-style.yikes-easy-mc-form .yikes-easy-mc-email{
		position:relative;
		display:block;
		vertical-align:top;
		width:100%;
		height:50px;
		border-radius:3px;
		-webkit-border-radius:3px;
		-moz-border-radius:3px;
		outline: none;
		text-align: center;
	}
	.restaurant-style.yikes-easy-mc-form .yikes-easy-mc-submit-button{
		position:relative;
		display:block;
		vertical-align:top;
		width:100%;
		height:50px;
		margin: 0;
	}

       .gem-team-style-6 .team-person-hover {
              padding: 32px;
         }
         .gem-team-style-6 .team-person-image {
		margin-bottom: 0;
	}
	
	@media (max-width: 1199px){
		 .wpb_row,  .wpb_row > *,
                 .row,  .row > * {
		        margin-top: 0 !important;
	         }

                 #footer-nav .row {
                        margin-top: -3em !important;
                 }

                 #footer-nav  .row > *{
                        margin-top:  3em !important;
                 }

                 .book-form .form-line{
		       margin-top: 0px;
	         }
                 .inner-custom-title.posts  .vc_custom_heading{
			font-size: 80px !important;
			line-height: 90px !important;
                        padding-left: 0;
		}
                 .custom-title-title  .title-xlarge{
		        font-size: 80px;
			line-height: 90px;
	          }
	}
	@media (max-width: 767px){
		.page-title-title .custom-title {
			padding: 70px 0 80px  50px;
		}
               .vc_custom_heading.custom-title-light{
                        font-size: 80px !important;
			line-height: 60px !important;
                 }
                  .custom-title-title  .title-xlarge{
		        padding: 0;
	          }
                 .vc_custom_heading.custom-title-bold{
                        font-size: 50px !important;
			line-height: 60px !important;
                 }
		.inner-custom-title  .vc_custom_heading{
			font-size: 80px !important;
			line-height: 40px !important;
                        padding-left: 0;
		}
                .gear-1,
	        .gear-2,
	        .gear-3,
	        .gear-4,
	        .gear-5,
	        .gear-6,
	        .gear-7{
		        display: none;
	         }
                   .custom-pageable  .vc_tta-tabs.vc_tta-has-pagination .vc_pagination{
                         top: 90% !important;
                }
	}


/*--------CONTACT FORM--------*/

/*---BORDER / RADIUS---*/
input[type="text"], input[type="password"], input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="number"], input[type="range"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="month"], input[type="week"], textarea, .combobox-wrapper, #page .woocommerce .select2-container .select2-selection--single, .select2-container .select2-dropdown, .chosen-container-single .chosen-single, .select2-container .select2-choice, select.wpcf7-form-control.wpcf7-select {
	border: 1px solid #42717C;
/* 	
-webkit-border-radius: 20px;
-webkit-border-top-left-radius: 0;
-moz-border-radius: 20px;
-moz-border-radius-topleft: 0;
border-radius: 20px;
border-top-left-radius: 0;
*/
}

/*---BORDER / RADIUS END---*/


/*---TXT COLOR---*/
input[type="text"], input[type="password"], input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="number"], input[type="range"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="month"], input[type="week"], textarea, select, .chosen-container-single .chosen-single, .select2-container .select2-choice, .widget_product_search form:before {
    color: #3c3950;
}
/*---TXT COLOR END---*/

/*---BACKGROUND---*/
/*
input[type="text"], input[type="password"], input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="number"], input[type="range"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="month"], input[type="week"], textarea, select, .chosen-container, .select2-container, .checkbox-sign, .radio-sign {
    background-color: #95B0B6;
}
*/
/*---BACKGROUND END---*/


/*SELECT FORM*/
/*
select{height: 50px;}
*/

/*SELECT FORM END*/


/*---BOTON ENVIAR---*/
/*
.wpcf7-form input[type='submit']:not(.gem-button-wpcf-custom){
    background-color: #cc8e12;
-webkit-border-radius: 20px;
-moz-border-radius: 20px;
border-radius: 20px;
}

.wpcf7-form input[type='submit']:not(.gem-button-wpcf-custom):hover{
    background-color: #7E5400;
}
*/
/*---BOTON ENVIAR END---*/

/*---LABEL---*/
.wpcf7-form label{color:#f1f1f1;}
/*---LABEL END---*/

.wpcf7-form p{ margin: 0 0 .5em;}

/*---CONTACT FORM END---*/



/*#primary-navigation .menu-toggle .menu-line-1, #primary-navigation .menu-toggle .menu-line-2, #primary-navigation .menu-toggle .menu-line-3, #perspective-menu-buttons.primary-navigation .menu-toggle .menu-line-1, #perspective-menu-buttons.primary-navigation .menu-toggle .menu-line-2, #perspective-menu-buttons.primary-navigation .menu-toggle .menu-line-3 {
    background-color: #397985;
}*/

#primary-navigation .menu-toggle .menu-line-1, #primary-navigation .menu-toggle .menu-line-2, #primary-navigation .menu-toggle .menu-line-3, #perspective-menu-buttons .menu-toggle .menu-line-1, #perspective-menu-buttons .menu-toggle .menu-line-2, #perspective-menu-buttons .menu-toggle .menu-line-3, #perspective-menu-buttons .perspective-toggle .menu-line-1, #perspective-menu-buttons .perspective-toggle .menu-line-2, #perspective-menu-buttons .perspective-toggle .menu-line-3 {
    position: absolute;
    height: 4px;
    width: 100%;
    left: 0;
    background: #397985;
}



/*---- TAGS WOOCOMMERCE--*/
.posted_in a{color:#2496bc}
/*---- TAGS WOOCOMMERCE END--*/


.comment-form-rating{color:#2496bc}

label {color:#2496bc}

.showcoupon{color:#2496bc}

.pagocentrado{text-align: center;}

ul.order_details {
    color: #ffffff;
}

ul.order_details li:before {
    color: #ffffff;
}

ul {list-style-type: none;}

.about_paypal{color:#2496bc}